import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_ContractWarning = _resolveComponent("ContractWarning")!

  return (_openBlock(), _createBlock(_component_ContractWarning, {
    class: "contract-warning-pending-validation",
    title: $data.contents.subscriptionApiLabel($options.apiLabel($props.contract.api)),
    message: $data.contents.message,
    iconName: "NotificationBell24"
  }, {
    default: _withCtx(() => [
      (
        $options.pendingRequestRoute != undefined &&
        $options.userHasWriteAccessOnCurrentApplication
      )
        ? (_openBlock(), _createBlock(_component_RouterLink, {
            key: 0,
            to: $options.pendingRequestRoute
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.viewRequest), 1)
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "message"]))
}