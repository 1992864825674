import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractWarningDisabled = _resolveComponent("ContractWarningDisabled")!
  const _component_ContractWarningPendingDeployment = _resolveComponent("ContractWarningPendingDeployment")!
  const _component_ContractWarningFailing = _resolveComponent("ContractWarningFailing")!
  const _component_ContractWarningPendingValidation = _resolveComponent("ContractWarningPendingValidation")!
  const _component_ContractWarningApiUnpublished = _resolveComponent("ContractWarningApiUnpublished")!
  const _component_ContractCard = _resolveComponent("ContractCard")!
  const _component_ContractWarningDefault = _resolveComponent("ContractWarningDefault")!
  const _component_CardListed = _resolveComponent("CardListed")!

  return (_openBlock(), _createBlock(_component_CardListed, {
    class: "contract-card-wrapper",
    "data-cy": $props.contract.id,
    noMargin: ""
  }, {
    default: _withCtx(() => [
      ($props.contract.status === $data.EContractStatus.DISABLED)
        ? (_openBlock(), _createBlock(_component_ContractWarningDisabled, {
            key: 0,
            contract: $props.contract,
            onContractDeleted: $options.emitContractChanged
          }, null, 8, ["contract", "onContractDeleted"]))
        : ($props.contract.status === $data.EContractStatus.PENDING_GATEWAY)
          ? (_openBlock(), _createBlock(_component_ContractWarningPendingDeployment, {
              key: 1,
              contract: $props.contract
            }, null, 8, ["contract"]))
          : ($props.contract.status === $data.EContractStatus.ERROR)
            ? (_openBlock(), _createBlock(_component_ContractWarningFailing, {
                key: 2,
                contract: $props.contract
              }, null, 8, ["contract"]))
            : ($props.contract.status === $data.EContractStatus.CREATED)
              ? (_openBlock(), _createBlock(_component_ContractWarningPendingValidation, {
                  key: 3,
                  contract: $props.contract
                }, null, 8, ["contract"]))
              : ($options.apiOfContractIsNotPublished($props.contract))
                ? (_openBlock(), _createBlock(_component_ContractWarningApiUnpublished, {
                    key: 4,
                    contract: $props.contract,
                    onContractDeleted: $options.emitContractChanged
                  }, null, 8, ["contract", "onContractDeleted"]))
                : ($props.contract.status === $data.EContractStatus.ENABLED)
                  ? (_openBlock(), _createBlock(_component_ContractCard, {
                      key: 5,
                      contract: $props.contract,
                      onContractChanged: $options.emitContractChanged
                    }, null, 8, ["contract", "onContractChanged"]))
                  : (_openBlock(), _createBlock(_component_ContractWarningDefault, {
                      key: 6,
                      contract: $props.contract
                    }, null, 8, ["contract"]))
    ]),
    _: 1
  }, 8, ["data-cy"]))
}