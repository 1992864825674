<template>
  <div class="contract-warning">
    <MIcon class="contract-warning__icon" :name="iconName" />

    <span class="contract-warning__title">{{ title }}</span>
    <span v-if="message != undefined" class="contract-warning__message">{{
      message
    }}</span>

    <div
      v-if="$slots.default != undefined"
      class="contract-warning__custom-section"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";

import { EContractStatus } from "@/commons/store/types";

export default {
  components: {
    MIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: undefined,
    },
    iconName: {
      type: String,
      default: "NotificationWarning24",
    },
  },
  data() {
    return {
      EContractStatus,
    };
  },
};
</script>

<style lang="scss">
.contract-warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 1rem;

  text-align: center;
  gap: 0.5rem;
}

.contract-warning__title {
  font-weight: var(--weight-bold);
}

.contract-warning__custom-section {
  margin-top: 0.5rem;
}
</style>
