<template>
  <RouterLink
    class="contract-card-documentation"
    :to="{
      name: 'apiDocumentationDetail',
      params: { id: contract.api.id },
    }"
    target="_blank"
  >
    <div class="contract-card-documentation__link">
      <MIcon name="ArrowArrowRight16" />
      <span class="link">{{ contents.documentation }} </span>
    </div>
  </RouterLink>
</template>

<script lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { PropType } from "vue";

import { Contract } from "@/commons/domain/models/contract";

import contents from "@/dashboard/contents/contract-item";

export default {
  components: {
    MIcon,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  data() {
    return {
      contents,
    };
  },
};
</script>

<style lang="scss">
.contract-card-documentation {
  @include set-text-s;
  padding: 0 1rem 1rem;
  margin: 0;
  background-color: var(--color-background-interface);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.contract-card-documentation__link {
  display: flex;
  align-items: center;
}
</style>
