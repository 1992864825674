import { ActionContext } from "vuex";

import { getApiRepository } from "@/commons/repositories/libs/get-api-repository";
import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";
import { getProductRepository } from "@/commons/repositories/libs/get-product-repository";
import { getTagRepository } from "@/commons/repositories/libs/get-tag-repository";
import { getZoneRepository } from "@/commons/repositories/libs/get-zone-repository";
import { finishAction } from "@/commons/store/helpers/finish-action";
import { flagLoadingProcess } from "@/commons/utils/flagProcess.utils";

import { ApisFetchParams } from "@/commons/domain/repositories/api-repository";
import { ProductFetchParams } from "@/commons/domain/repositories/product-repository";

import { State } from "./types";

async function loadZones({ commit }: ActionContext<State, undefined>) {
  flagLoadingProcess("loadZones", async () => {
    finishAction({
      commit,
      propertiesToReplace: {
        zones: await getZoneRepository().findAll(),
      },
    });
  });
}

async function loadProducts(
  { commit }: ActionContext<State, undefined>,
  fetchParams: ProductFetchParams,
) {
  flagLoadingProcess("products", async () => {
    finishAction({
      commit,
      propertiesToReplace: {
        products: await getProductRepository().find(fetchParams),
      },
    });
  });
}

async function loadScopesProducts(
  { commit }: ActionContext<State, undefined>,
  fetchParams: ProductFetchParams,
) {
  await flagLoadingProcess("scopesProducts", async () => {
    finishAction({
      commit,
      propertiesToReplace: {
        scopesProducts: await getProductRepository().find(fetchParams),
      },
    });
  });
}

async function loadTags({ commit }: ActionContext<State, any>) {
  await flagLoadingProcess("tags", async () => {
    const tagsDictionary = await getTagRepository().find({
      types: "BU,PLATFORM",
      size: 100,
    });

    finishAction({
      commit,
      propertiesToReplace: {
        tags: tagsDictionary,
      },
    });
  });
}

async function loadApis(
  { commit }: ActionContext<State, undefined>,
  fetchParams: ApisFetchParams,
) {
  await flagLoadingProcess("apis", async () => {
    const apis = await getApiRepository().cancelAndFind(fetchParams);

    finishAction({
      commit,
      propertiesToReplace: {
        apis,
      },
    });
  });
}

async function loadExtensionRules({ commit }: ActionContext<State, undefined>) {
  await flagLoadingProcess("extensionRules", async () => {
    const extensionRules = await getContractRepository().getExtensionRules();

    finishAction({
      commit,
      propertiesToReplace: {
        extensionRules,
      },
    });
  });
}

export default {
  loadZones,
  loadProducts,
  loadScopesProducts,
  loadTags,
  loadApis,
  loadExtensionRules,
};
