import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "contract-secret__secret-display" }
const _hoisted_3 = { class: "contract-secret__eye-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconCopy = _resolveComponent("IconCopy")!
  const _component_IconEyeOpen = _resolveComponent("IconEyeOpen")!
  const _component_IconEyeClose = _resolveComponent("IconEyeClose")!

  return (_openBlock(), _createElementBlock("div", {
    class: "contract-secret",
    title: $data.contents.copyClipboardTitle,
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => ($data.isVisibleSecret = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => ($data.isVisibleSecret = false)),
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.copyToClipboard && $options.copyToClipboard(...args)))
  }, [
    _createVNode(_component_IconCopy, { class: "contract-secret__copy-icon" }),
    _createElementVNode("span", _hoisted_2, _toDisplayString($options.secretDisplay), 1),
    _createElementVNode("span", _hoisted_3, [
      ($data.isVisibleSecret)
        ? (_openBlock(), _createBlock(_component_IconEyeOpen, { key: 0 }))
        : (_openBlock(), _createBlock(_component_IconEyeClose, { key: 1 }))
    ])
  ], 40, _hoisted_1))
}