<template>
  <ContractWarning
    class="contract-warning-pending-validation"
    :title="contents.subscriptionApiLabel(apiLabel(contract.api))"
    :message="contents.message"
    iconName="NotificationBell24"
  >
    <RouterLink
      v-if="
        pendingRequestRoute != undefined &&
        userHasWriteAccessOnCurrentApplication
      "
      :to="pendingRequestRoute"
      >{{ contents.viewRequest }}</RouterLink
    >
  </ContractWarning>
</template>

<script lang="ts">
import { PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";

import { Contract } from "@/commons/domain/models/contract";
import { fetchPendingNewContractRequest } from "@/commons/services/request/request-service";

import {
  EContractStatus,
  ERequestFilteringVisibility,
} from "@/commons/store/types";

import contents from "@/commons/contents/contract-warning-pending-validation";

export default {
  components: { ContractWarning },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  data() {
    return {
      contents,
      EContractStatus,
      pendingRequest: undefined,
    };
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    // We should check WRITE access on Contract instead of Application but today there is no way to know if the user has write access on Contract object
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
    requestVisibility() {
      return this.userIsAdmin
        ? ERequestFilteringVisibility.ALL_VALIDABLE_BY_ADMIN
        : ERequestFilteringVisibility.SUBMITTED;
    },
    pendingRequestRoute(): RouteLocationRaw | undefined {
      return this.pendingRequest != undefined
        ? {
            name: "requestManager",
            query: {
              visibility: this.requestVisibility,
              requestId: this.pendingRequest.id,
            },
          }
        : undefined;
    },
  },
  async created() {
    this.pendingRequest = await fetchPendingNewContractRequest(
      this.contract.id,
    );
  },
  methods: {
    apiLabel,
  },
};
</script>
