import { ApiDocumentationPageRepository } from "./commons/domain/repositories/api-documentation-page-repository";
import { ApiRepository } from "./commons/domain/repositories/api-repository";
import { ApplicationRepository } from "./commons/domain/repositories/application-repository";
import { ConfigRepository } from "./commons/domain/repositories/config-repository";
import { ContractRepository } from "./commons/domain/repositories/contract-repository";
import { GroupWithUsersAclsRepository } from "./commons/domain/repositories/group-with-users-acls-repository";
import { ProductRepository } from "./commons/domain/repositories/product-repository";
import { RequestSummaryRepository } from "./commons/domain/repositories/request-summary-repository";
import { TagRepository } from "./commons/domain/repositories/tag-repository";
import { UserRepository } from "./commons/domain/repositories/user-repository";
import { ZoneRepository } from "./commons/domain/repositories/zone-repository";
import { HumanReadableUrl } from "./commons/domain/services/human-readable-url";
import { ValidationErrorMapper } from "./commons/domain/validation/validation-error-mapper";
import { Validator } from "./commons/domain/validation/validator";
import { ClassValidationErrorMapper } from "./commons/forms/class-validator/class-validation-error-mapper";
import { ClassValidator } from "./commons/forms/class-validator/class-validator";
import { SlugifyUrl } from "./commons/forms/slugify-url";
import { ApiDocumentationPageHttpRepository } from "./commons/repositories/api-documentation-page/api-documentation-page-http-repository";
import { ApiHttpRepository } from "./commons/repositories/api/api-http-repository";
import { ApplicationHttpRepository } from "./commons/repositories/application/application-http-repository";
import { ConfigHttpRepository } from "./commons/repositories/config/config-http-repository";
import { ContractHttpRepository } from "./commons/repositories/contract/contract-http-repository";
import { GroupWithUsersAclsHttpRepository } from "./commons/repositories/group-with-users-acls/group-with-users-acls-http-repository";
import { ProductHttpRepository } from "./commons/repositories/product/product-http-repository";
import { RequestSummaryHttpRepository } from "./commons/repositories/request-summary/request-summary-http-repository";
import { TagHttpRepository } from "./commons/repositories/tag/tag-http-repository";
import { UserHttpRepository } from "./commons/repositories/user/user-http-repository";
import { ZoneHttpRepository } from "./commons/repositories/zone/zone-http-repository";
import { RequestHandlerAxios } from "./commons/request-handlers/request-handler-axios";
import { interfaces } from "inversify";
import { container } from "inversify-props";

import { BatchRepository } from "@/commons/domain/repositories/batch-repository";
import { GatewayRepository } from "@/commons/domain/repositories/gateway-repository";
import { ScopeRepository } from "@/commons/domain/repositories/scope-repository";
import { BatchHttpRepository } from "@/commons/repositories/batch/batch-http-repository";
import { GatewayHttpRepository } from "@/commons/repositories/gateway/gateway-http-repository";
import { ScopeHttpRepository } from "@/commons/repositories/scope/scope-http-repository";

import { TYPES } from "./types";

export class Container {
  private static _instance: Container | null = null;
  private readonly _container: interfaces.Container;

  private constructor() {
    container
      .bind<Validator>(TYPES.VALIDATOR)
      .to(ClassValidator)
      .inSingletonScope();
    container
      .bind<ValidationErrorMapper>(TYPES.VALIDATION_ERROR_MAPPER)
      .to(ClassValidationErrorMapper)
      .inSingletonScope();
    container
      .bind<HumanReadableUrl>(TYPES.SERVICE_HUMAN_READABLE_URL)
      .to(SlugifyUrl)
      .inSingletonScope();
    container
      .bind<RequestHandlerAxios>(TYPES.REQUEST_HANDLER)
      .to(RequestHandlerAxios)
      .inSingletonScope();
    container
      .bind<ApiRepository>(TYPES.API_REPOSITORY)
      .to(ApiHttpRepository)
      .inSingletonScope();
    container
      .bind<ZoneRepository>(TYPES.ZONE_REPOSITORY)
      .to(ZoneHttpRepository)
      .inSingletonScope();
    container
      .bind<ProductRepository>(TYPES.PRODUCT_REPOSITORY)
      .to(ProductHttpRepository)
      .inSingletonScope();
    container
      .bind<BatchRepository>(TYPES.BATCH_REPOSITORY)
      .to(BatchHttpRepository)
      .inSingletonScope();
    container
      .bind<ApiDocumentationPageRepository>(
        TYPES.API_DOCUMENTATION_PAGE_REPOSITORY,
      )
      .to(ApiDocumentationPageHttpRepository)
      .inSingletonScope();
    container
      .bind<ContractRepository>(TYPES.CONTRACT_REPOSITORY)
      .to(ContractHttpRepository)
      .inSingletonScope();
    container
      .bind<ConfigRepository>(TYPES.CONFIG_REPOSITORY)
      .to(ConfigHttpRepository)
      .inSingletonScope();
    container
      .bind<UserRepository>(TYPES.USER_REPOSITORY)
      .to(UserHttpRepository)
      .inSingletonScope();
    container
      .bind<ApplicationRepository>(TYPES.APPLICATION_REPOSITORY)
      .to(ApplicationHttpRepository)
      .inSingletonScope();
    container
      .bind<TagRepository>(TYPES.TAG_REPOSITORY)
      .to(TagHttpRepository)
      .inSingletonScope();
    container
      .bind<RequestSummaryRepository>(TYPES.REQUEST_SUMMARY_REPOSITORY)
      .to(RequestSummaryHttpRepository)
      .inSingletonScope();
    container
      .bind<GroupWithUsersAclsRepository>(
        TYPES.GROUP_WITH_USERS_ACLS_REPOSITORY,
      )
      .to(GroupWithUsersAclsHttpRepository)
      .inSingletonScope();
    container
      .bind<GatewayRepository>(TYPES.GATEWAY_REPOSITORY)
      .to(GatewayHttpRepository)
      .inSingletonScope();
    container
      .bind<ScopeRepository>(TYPES.SCOPE_REPOSITORY)
      .to(ScopeHttpRepository)
      .inSingletonScope();
    this._container = container;
  }

  static instance() {
    if (this._instance === null) {
      Container._instance = new Container();
    }

    return this._instance!._container;
  }
}
