<template>
  <LayerModalFormConfirm
    class="application-first-redirect-uri-modal"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="
      validatedRedirectUri == undefined ||
      redirectUriValidationError != undefined ||
      isDoingValidation
    "
    :confirmBtnLabel="confirmBtnLabel"
    @submit="submit"
    @close="close"
  >
    <StepperApiSubscription
      :stepperDescription="contents.stepperDescription"
      :stepperSteps="stepperSteps"
    />

    <div class="application-first-redirect-uri-modal__description-section">
      <h4 class="application-first-redirect-uri-modal__title">
        {{ contents.title }}
      </h4>

      <Markdown
        class="application-first-redirect-uri-modal__description"
        :content="contents.description"
      />
    </div>

    <ViewHelpButton
      class="application-first-redirect-uri-modal__view-help-button"
      documentationAnchor="#consume-an-api"
    />

    <DvpField
      class="application-first-redirect-uri-modal__redirect-uri-field"
      required
      :label="contents.redirectUriField"
      :errorMessage="redirectUriValidationError"
    >
      <MTextInput
        :modelValue="redirectUri"
        :placeholder="contents.redirectUriPlaceholder"
        :isInvalid="redirectUriValidationError != undefined"
        @update:modelValue="updateRedirectUri"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { PropType } from "vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import StepperApiSubscription from "@/dashboard/views/AppDetailsContracts/StepperApiSubscription.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { debounce } from "@/commons/libs/utils/debouncer";
import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";

import contents from "@/dashboard/contents/application-first-redirect-uri-modal";
import contentSubscriptionStepper from "@/dashboard/contents/subscription-stepper";

export default {
  components: {
    StepperApiSubscription,
    ViewHelpButton,
    LayerModalFormConfirm,
    MTextInput,
    DvpField,
    Markdown,
  },
  props: {
    oauthProvider: {
      type: String,
      required: true,
    },
    apiProvidesScopes: {
      type: Boolean,
      required: true,
    },
    passedSteps: {
      type: Array as PropType<{ label: string; isCurrent: boolean }[]>,
      required: true,
    },
    requiresManagersValidation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit", "onClose"],
  data() {
    return {
      contents,
      redirectUri: undefined, // value of the input. Useful so the input displays the new value when the user types a new character instead of waiting for the validation
      validatedRedirectUri: undefined,
      redirectUriValidationError: undefined,
      isDoingValidation: false,
    };
  },
  computed: {
    stepperSteps() {
      const steps = [...this.passedSteps];

      steps.push({
        label: contentSubscriptionStepper.stepSetFirstRedirectUriTitle,
        isCurrent: true,
      });

      if (this.apiProvidesScopes) {
        steps.push({
          label: contentSubscriptionStepper.stepSelectScopesTitle,
          isCurrent: false,
        });
      }

      if (this.requiresManagersValidation) {
        steps.push({
          label: contentSubscriptionStepper.stepSendValidationRequestTitle,
          isCurrent: false,
        });
      }

      return steps;
    },
    confirmBtnLabel(): string | undefined {
      return this.apiProvidesScopes
        ? contentSubscriptionStepper.nextStepBtnLabel
        : undefined; // use default confirm button label
    },
  },
  created() {
    this.debouncedValidateRedirectUri = debounce(this.validateRedirectUri);
  },
  methods: {
    updateRedirectUri(newRedirectUri: string): void {
      this.isDoingValidation = true;
      this.redirectUri = newRedirectUri;
      this.debouncedValidateRedirectUri(newRedirectUri);
    },
    async validateRedirectUri(redirectUri): Promise<void> {
      const validation = await getApplicationRepository().validateRedirectUri(
        redirectUri,
        this.oauthProvider,
      );
      this.redirectUriValidationError = validation.message;
      this.validatedRedirectUri = redirectUri;
      this.isDoingValidation = false;
    },
    submit(): void {
      this.$emit("submit", this.validatedRedirectUri);
      this.close();
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.application-first-redirect-uri-modal__description-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.application-first-redirect-uri-modal__title {
  margin: 0;
}

.application-first-redirect-uri-modal__view-help-button {
  align-self: flex-start;
}
</style>
