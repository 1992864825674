<template>
  <div class="contract-api-keys-display">
    <ul class="contract-api-keys-display__tabs-menu">
      <li
        v-for="(token, index) in tokens"
        :key="index"
        class="contract-api-keys-display__tab"
        :class="
          activeTokenIndex === index
            ? 'contract-api-keys-display__tab--active'
            : 'contract-api-keys-display__tab--inactive'
        "
        :data-status="getTokenStyleStatus(token)"
        @click="$emit('update:activeTokenIndex', index)"
      >
        <span>{{
          index === 0 ? contents.currentApiKey : contents.newApiKey
        }}</span>

        <span data-cy="token-expiration-date">
          {{ getMessageText(token) }}
        </span>
      </li>
    </ul>

    <div
      class="contract-api-keys-display__tab-content"
      :data-status="getTokenStyleStatus(tokens[activeTokenIndex])"
    >
      <ContractSecret :secret="tokens[activeTokenIndex].key" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from "vue";

import ContractSecret from "./ContractSecret.vue";

import { toLocaleDate } from "@/commons/utils/date-utils";

import { Contract } from "@/commons/domain/models/contract";
import { Token } from "@/commons/domain/models/token";

import { ETokenStatus } from "@/commons/store/types";

import contentsContractItem from "@/dashboard/contents/contract-item";
import contents from "@/dashboard/contents/tokens-display";

export default {
  components: {
    ContractSecret,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    activeTokenIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:activeTokenIndex"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    tokens(): Token[] {
      return Object.values(this.contract.tokens);
    },
  },
  methods: {
    getMessageText(token: Token): string {
      let messageText = "";
      if (token.status === ETokenStatus.DISABLED) {
        messageText = contentsContractItem.disabledByApiManager;
      } else if (token.expireAt != undefined) {
        const expiredAtFormatted = toLocaleDate(token.expireAt);
        if (token.status === ETokenStatus.EXPIRED) {
          messageText = `${contentsContractItem.expiredSince} ${expiredAtFormatted}`;
        } else {
          messageText = `${contentsContractItem.expiresAt} ${expiredAtFormatted}`;
        }
      }
      return messageText;
    },
    getTokenStyleStatus(token: Token) {
      //ABOUT_TO_EXPIRE is a status only used for styling the token on the frontend, it's not an official token status
      return token?.isAboutToExpire ? "ABOUT_TO_EXPIRE" : token?.status;
    },
  },
};
</script>

<style lang="scss">
.contract-api-keys-display__tabs-menu {
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.contract-api-keys-display__tab {
  @include set-text-xs;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;

  height: 3rem;

  font-weight: var(--weight-medium);
}

.contract-api-keys-display__tab--inactive:hover {
  cursor: pointer;
  box-shadow: var(--m-shadow-small);
}

.contract-api-keys-display__tab--active {
  color: var(--color-text-primary-invert);
}

.contract-api-keys-display__tab--active[data-status="ENABLED"] {
  background-color: var(--color-brand-primary);
}

.contract-api-keys-display__tab--active[data-status="EXPIRED"] {
  background-color: var(--color-status-danger);
}

.contract-api-keys-display__tab--active[data-status="ABOUT_TO_EXPIRE"] {
  background-color: var(--color-status-warning);
}

.contract-api-keys-display__tab--active[data-status="DISABLED"] {
  background-color: var(--color-status-disabled);
}

.contract-api-keys-display__tab[data-status="PENDING"] {
  color: var(--color-grey);
  pointer-events: none;
  background-color: var(--color-background-secondary-light);
  opacity: 0.5;
}

.contract-api-keys-display__tab-content {
  padding: 0.75rem;
  margin: 0;
  background-color: var(--color-background-interface);
}
.contract-api-keys-display__tab-content[data-status="ENABLED"] {
  border-top: 2px solid var(--color-brand-primary);
}
.contract-api-keys-display__tab-content[data-status="EXPIRED"] {
  border-top: 2px solid var(--color-status-danger);
}
.contract-api-keys-display__tab-content[data-status="DISABLED"] {
  border-top: 2px solid var(--color-status-disabled);
}
.contract-api-keys-display__tab-content[data-status="ABOUT_TO_EXPIRE"] {
  border-top: 2px solid var(--color-status-warning);
}
</style>
