<template>
  <div v-if="!isLoading" class="reporting-application">
    <MNotification v-if="errorMessage" type="danger">
      {{ errorMessage }}
    </MNotification>
    <MNotification
      v-if="!errorMessage && !isFetchingReporting && hits.length === 0"
      type="warning"
    >
      {{ contents.errorNoTraffic }}
    </MNotification>
    <MNotification
      v-if="
        !errorMessage &&
        !isFetchingReporting &&
        currentApplicationContractsList.length === 0
      "
      type="warning"
    >
      {{ contents.errorNotSubscribeToApi }}
    </MNotification>
    <ReportingApplicationFilters
      class="reporting__filters"
      :applicationId="id"
      @updateSelectedFilters="changeUrlQuery"
    />
    <HistogramApplication
      v-if="hits.length > 0 && !isFetchingReporting"
      :applicationId="id"
      @updateSelectedFilters="changeUrlQuery"
    />
  </div>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import HistogramApplication from "./HistogramApplication.vue";
import ReportingApplicationFilters from "./ReportingApplicationFilters.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

import contents from "@/dashboard/contents/app-details-reporting";

export default {
  name: "ReportingApplication",
  components: {
    MNotification,
    ReportingApplicationFilters,
    HistogramApplication,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    filters: {
      default: () => ({
        from: "one_hour_ago",
        to: "now",
        apis: [],
        status: [],
        methods: [],
        numHits: 100,
      }),
    },
  },
  data() {
    return {
      contents,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    currentApplication() {
      return this.$store.getters["currentApplication"];
    },
    currentApplicationContractsList() {
      return this.$store.getters["currentApplicationContractsList"];
    },
    errorMessage() {
      return this.$store.getters["reporting/errorMessage"];
    },
    hits() {
      return this.$store.getters["reporting/hits"];
    },
    isFetchingReporting() {
      return this.$store.getters["reporting/isFetchingReporting"];
    },
  },
  watch: {
    filters: {
      async handler(newFilters) {
        this.$store.commit("reporting/updateSelectedFilters", newFilters);
        await Promise.all([
          this.$store.dispatch(
            "reporting/loadApplicationReportingFiltersOptions",
            this.id,
          ),
          this.$store.dispatch("reporting/loadApplicationReporting", this.id),
        ]);
      },
    },
  },
  async mounted() {
    this.$store.commit("reporting/updateSelectedFilters", this.filters);
    await Promise.all([
      this.$store.dispatch(
        "reporting/loadApplicationReportingFiltersOptions",
        this.id,
      ),
      this.$store.dispatch("reporting/loadApplicationReporting", this.id),
    ]);
  },
  methods: {
    changeUrlQuery(partial: any) {
      changeUrlQuery(partial);
    },
  },
};
</script>

<style lang="scss">
.reporting-application {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}
</style>
