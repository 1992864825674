export default {
  pendingRequest: "PENDING REQUEST",
  privacies: {
    PRIVATE: {
      label: "Private",
      description: "API managers and specific groups can access your API",
    },
    INTERNAL: {
      label: "Internal",
      description: "All ADEO users, connecting with LDAP, have access",
    },
    PARTNER: {
      label: "Partner",
      description:
        "In addition to the internal users, named partners using social access have access",
    },
    PUBLIC: {
      label: "Public",
      description: "All users, without a link with ADEO group, have access",
    },
  },
  apiShouldBeAccessibleOnInternet:
    "Your API needs to be published on a gateway accessible on the internet. Go to publication section",
};
