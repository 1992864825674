import { Commit } from "vuex";

import { saveOnStore } from "@/commons/store/helpers/store-saving-utils";

import { Dictionary } from "@/commons/domain/models/dictionary";

import { NotificationContent } from "@/commons/store/modules/notification/types";

/**
 * This function takes data objects as parameters for saving them on the store
 * It doesn't handle redirections or errors so these handlings can be externalized
 * This function was designed to be used together with the error catching system using listeners at the start of the application
 */
export function finishAction({
  commit,
  path = [],
  propertiesToReplace = {},
  propertiesToMerge = {},
  propertiesDictionaryToMerge = {},
  toastNotification,
  afterSave,
}: {
  commit: Commit;
  propertiesToReplace?: Dictionary<any>;
  propertiesToMerge?: Dictionary<any>;
  propertiesDictionaryToMerge?: Dictionary<Dictionary<{ id: string }>>;
  path?: string | string[];
  toastNotification?: NotificationContent;
  afterSave?: () => void;
}) {
  saveOnStore({
    commit,
    path,
    propertiesToReplace,
    propertiesToMerge,
    propertiesDictionaryToMerge,
  });

  if (toastNotification != undefined) {
    commit("postSuccessNotification", toastNotification);
  }

  if (afterSave != undefined) {
    afterSave();
  }
}
