import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contract-unsubscription-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "contract-unsubscription-modal",
    disabledConfirmBtn: $data.form.errors.length > 0,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString($data.contents.confirmationTitle), 1),
      ($props.isOAuthContractWithSharedOrMultiLinedToken)
        ? (_openBlock(), _createBlock(_component_Markdown, {
            key: 0,
            content: 
          $data.contents.oAuthContractWithSharedOrMultiLinedTokenConfirmationDescription(
            $options.apiLabel,
          )
        
          }, null, 8, ["content"]))
        : ($props.isOAuthContract)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_Markdown, {
                content: $data.contents.oAuthContractConfirmationDescription($options.apiLabel)
              }, null, 8, ["content"]),
              _createVNode(_component_MNotification, {
                "data-cy": "notification-not-shared-nor-multilined-token",
                type: "warning",
                title: $data.contents.notSharedNorMultilinedTokenNotificationTitle
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString($data.contents.notSharedNorMultilinedTokenNotificationMessage), 1)
                ]),
                _: 1
              }, 8, ["title"])
            ], 64))
          : (_openBlock(), _createBlock(_component_Markdown, {
              key: 2,
              content: $data.contents.apiKeyContractConfirmationDescription($options.apiLabel)
            }, null, 8, ["content"])),
      _createVNode(_component_DvpField, {
        class: "contract-unsubscription-modal__confirmation-text",
        required: "",
        errorMessage: $data.form.firstError('text')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
            type: "text",
            placeholder: $data.contents.unsubscribe,
            isInvalid: $data.form.hasError('text')
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["errorMessage"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit", "onClose"]))
}