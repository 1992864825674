export default {
  menuTitle: "Credentials actions",

  rotateApiKey: "Rotate this API Key (create new one)",
  extendApiKey: (extensionLimit: string) =>
    `Extend for ${extensionLimit} months`,
  deleteApiKey: "Delete this API Key",
  unsubscribe: "Unsubscribe",
  recommended: "Recommended",
};
