<script setup lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { computed, onMounted, PropType, reactive, ref } from "vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { Debouncer } from "@/commons/libs/utils/debouncer";

import { IPRange } from "@/commons/domain/models/ip-range";
import { CreateOrUpdateIPRangeForm } from "@/commons/forms/create-or-update-ip-range-form";
import { validateIPRange } from "@/commons/services/application/application-ip-range.service";

import contents from "@/dashboard/contents/ip-addresses-edition-modal";

const props = defineProps({
  applicationId: {
    type: String,
    required: true,
  },
  formData: {
    type: Object as PropType<IPRange>,
    default: () => ({ address: "", comment: "" }),
  },
  confirmBtnLabel: {
    type: String,
    default: () => contents.addNewIPAddressBtnLabel,
  },
});

const emit = defineEmits(["submit", "onClose"]);

const debouncer = new Debouncer();

const form = reactive(CreateOrUpdateIPRangeForm.create());

const backendError = ref(undefined as string);

const debouncedCheckIntersection = () => {
  debouncer.debounce(() => checkIntersection(), 100);
};

const checkIntersection = async () => {
  if (
    form.firstError("address") != undefined ||
    ipAddressWasNotUpdated.value === true
  ) {
    backendError.value = undefined;
  } else {
    const result = await validateIPRange(getPayload(), props.applicationId);

    backendError.value = result.valid ? undefined : result.message;
  }
};

onMounted(async () => {
  await form.init(props.formData);
});

const submit = async () => {
  await form.validate();
  emit("submit", getPayload());
  emit("onClose");
};

const getPayload = () => {
  const payload: IPRange = {
    address:
      form.address.split("/").length === 1
        ? `${form.address}/32`
        : form.address,
    comment: form.comment,
    id: props.formData?.id,
  };

  return payload;
};

const formWasNotUpdated = computed(() => {
  if (props.formData != undefined) {
    return (
      ipAddressWasNotUpdated.value && props.formData.comment === form.comment
    );
  }
  return false;
});

const ipAddressWasNotUpdated = computed(() => {
  if (props.formData != undefined) {
    const oldIp = props.formData.address;
    const newIp = form.address;
    if (oldIp.endsWith("/32")) {
      return oldIp === newIp || oldIp.split("/")[0] === newIp;
    } else {
      return oldIp === newIp;
    }
  }
  return false;
});

const disabledSubmit = computed(() => {
  return (
    (props.formData != undefined && formWasNotUpdated.value) ||
    form.errors.length > 0 ||
    backendError.value != undefined
  );
});
</script>

<template>
  <LayerModalFormConfirm
    class="ip-addresses-edition-modal"
    confirmBtnTheme="solid"
    :confirmBtnLabel="confirmBtnLabel"
    :disabledConfirmBtn="disabledSubmit"
    @submit="submit"
    @close="emit('onClose')"
  >
    <div>
      <p>{{ contents.ipFilteringUsageMessage }}</p>
      <p>{{ contents.publicIPAddressesMessage }}</p>
      <p>{{ contents.blockedRequestsMessage }}</p>
    </div>

    <DvpField
      data-cy="form-ip-address-field"
      required
      :label="contents.ipAddressRangeLabel"
      :helpText="contents.ipAddressRangeCIDRLabel"
      :errorMessage="form.firstError('address') || backendError"
    >
      <MTextInput
        v-model="form.address"
        data-cy="form-ip-address-input"
        :placeholder="contents.ipAddressRangePlaceholder"
        :isInvalid="
          form.firstError('address') != undefined || backendError != undefined
        "
        @update:model-value="debouncedCheckIntersection()"
      />
    </DvpField>

    <MNotification type="information" data-cy="default-cidr-notification">
      {{ contents.cidrBlockNotificationMessage }}
    </MNotification>

    <DvpField
      :label="contents.commentLabel"
      :requirementText="contents.commentRequirementText"
      :errorMessage="form.firstError('comment')"
    >
      <MTextInput
        v-model="form.comment"
        data-cy="form-ip-address-comment-input"
        :isInvalid="form.firstError('comment') != undefined"
      />
    </DvpField>

    <ViewHelpButton
      class="ip-addresses-edition-modal__view-help-button"
      documentationAnchor="#application"
    />
  </LayerModalFormConfirm>
</template>

<style lang="scss">
.ip-addresses-edition-modal__view-help-button {
  align-self: flex-start;
}
</style>
