<template>
  <div v-if="zone && zone.name" class="gateway-label-symbols">
    <img :src="datacenterIcon" class="gateway-label-symbols__icon" />
    <img :src="zoneFlag" class="gateway-label-symbols__icon" />
    <span class="gateway-label-symbols__zone-name">
      {{ zone.name }}
    </span>
  </div>
</template>
<script lang="ts">
import { PropType } from "vue";

import { getDatacenterIcon } from "@/commons/utils/zone-utils";

import { Zone } from "@/commons/domain/models/zone";

export default {
  props: {
    zone: {
      type: Object as PropType<Zone>,
      required: true,
    },
  },
  data() {
    return {
      southAfricaFlag: require("@/commons/assets/images/access-url/south-africa-flag.svg"),
      brazilFlag: require("@/commons/assets/images/access-url/brazil-flag.svg"),
      russiaFlag: require("@/commons/assets/images/access-url/russia-flag.svg"),
      europeFlag: require("@/commons/assets/images/access-url/europe-flag.svg"),
    };
  },
  computed: {
    zoneFlag() {
      if (
        this.zone.name &&
        this.zone.name.toLowerCase().includes("south africa")
      ) {
        return this.southAfricaFlag;
      }

      if (this.zone.name && this.zone.name.toLowerCase().includes("russia")) {
        return this.russiaFlag;
      }

      if (this.zone.name && this.zone.name.toLowerCase().includes("brazil")) {
        return this.brazilFlag;
      }

      return this.europeFlag;
    },
    datacenterIcon() {
      return getDatacenterIcon(this.zone);
    },
  },
};
</script>
<style lang="scss">
.gateway-label-symbols {
  display: flex;
  align-items: center;
}

.gateway-label-symbols__icon {
  width: 24px;
  margin-right: 0.5rem;
}

.gateway-label-symbols__zone-name {
  margin-right: 1rem;
}
</style>
