import { HttpRepository } from "@/commons/repositories/libs/http-repository";

import { Injectable } from "@/commons/domain/di/injectable";
import { RateLimit } from "@/commons/domain/models/rate-limit";
import { TokenExtensionRules } from "@/commons/domain/models/token-extension-rules";
import {
  ContractParametersDTO,
  ContractRepository,
} from "@/commons/domain/repositories/contract-repository";
import { ContractDto } from "@/commons/dtos/contract-dto";
import { CreateContractDto } from "@/commons/dtos/create-contract-dto";
import { PagedResourceDto } from "@/commons/dtos/paged-resource-dto";
import { ContractMapper } from "@/commons/mappers/contract-mapper";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

@Injectable()
export class ContractHttpRepository
  extends HttpRepository
  implements ContractRepository
{
  public async findUserSandboxContractsByApi(apiId: string) {
    const response = await this.requestHandler.get<
      PagedResourceDto<ContractDto>
    >(`/contracts`, {
      params: { apiId, userSandbox: true },
    });
    return ContractMapper.toDomain(response.data);
  }

  public async createUserSandboxContractsByApi(
    apiId: string,
    oauthFlow?: EOauthFlow,
    redirectUris?: string[],
  ) {
    const response = await this.requestHandler.post<ContractDto>(
      `/contracts/sandbox/${apiId}`,
      { oauthFlow, redirectUris },
    );
    return ContractMapper.toContractDomain(response.data);
  }

  public async findById(contractId: string) {
    const response = await this.requestHandler.get<ContractDto>(
      `/contracts/${contractId}`,
    );
    return ContractMapper.toContractDomain(response.data);
  }

  // TODO remove findByApi() or findByApplication() because they both do the same thing and take the same ContractParametersDTO.
  // Have a more general find() function instead ?
  public async findByApi(params?: ContractParametersDTO, cancellable = true) {
    const response = cancellable
      ? await this.requestHandler.cancelAndGet<PagedResourceDto<ContractDto>>(
          `/contracts`,
          { params },
        )
      : await this.requestHandler.get<PagedResourceDto<ContractDto>>(
          `/contracts`,
          { params },
        );
    return ContractMapper.toDomain(response.data);
  }

  public async findByApplication(params?: ContractParametersDTO) {
    const response = await this.requestHandler.get<
      PagedResourceDto<ContractDto>
    >(`/contracts`, { params });

    return ContractMapper.toDomain(response.data);
  }

  public async getExtensionRules() {
    const response = await this.requestHandler.get<TokenExtensionRules>(
      "/tokens/extension-rules",
    );
    return response.data;
  }

  public async deleteToken(tokenId: string, contractId?: string) {
    await this.requestHandler.delete(`/tokens/${tokenId}`, {
      params: { contractId },
    });
  }

  public async rotateToken(appId: string, contractId: string, tokenId: string) {
    await this.requestHandler.post(`/tokens/${tokenId}/_rotate`);
    return this.findById(contractId);
  }

  public async extendToken(appId: string, contractId: string, tokenId: string) {
    await this.requestHandler.post(`/tokens/${tokenId}/_extend`);
    return this.findById(contractId);
  }

  public async disableToken(
    appId: string,
    contractId: string,
    tokenId: string,
  ) {
    await this.requestHandler.post(`/tokens/${tokenId}/_disable`);
    return this.findById(contractId);
  }

  public async enableToken(appId: string, contractId: string, tokenId: string) {
    await this.requestHandler.post(`/tokens/${tokenId}/_enable`);
    return this.findById(contractId);
  }

  public async createContract(createContractDto: CreateContractDto) {
    const response = await this.requestHandler.post<ContractDto>(
      `/contracts`,
      createContractDto,
    );

    return ContractMapper.toContractDomain(response.data);
  }

  public async deleteContract(contractId: string) {
    await this.requestHandler.delete<void>(`/contracts/${contractId}`);
  }

  public async createRateLimit(
    appId: string,
    contractId: string,
    rateLimit: RateLimit,
  ) {
    await this.requestHandler.post(
      `/contracts/${contractId}/ratelimits`,
      rateLimit,
    );
    return this.findById(contractId);
  }

  public async updateRateLimit(
    appId: string,
    contractId: string,
    rateLimit: RateLimit,
  ) {
    await this.requestHandler.put(
      `/contracts/${contractId}/ratelimits/${rateLimit.id}`,
      rateLimit,
    );
    return this.findById(contractId);
  }

  public async deleteRateLimit(
    appId: string,
    contractId: string,
    rateLimitId: string,
  ) {
    await this.requestHandler.delete(
      `/contracts/${contractId}/ratelimits/${rateLimitId}`,
    );
    return this.findById(contractId);
  }

  public async enableContract(appId: string, contractId: string) {
    await this.requestHandler.post(`/contracts/${contractId}/_enable`);

    return this.findById(contractId);
  }

  public async disableContract(appId: string, contractId: string) {
    await this.requestHandler.post(`/contracts/${contractId}/_disable`);

    return this.findById(contractId);
  }

  public async checkOAuthTokensStatusesOnPingSide(
    tokenIds: string[],
  ): Promise<string[]> {
    const response = await this.requestHandler.post<string[]>(
      `/tokens/_checkStatus`,
      tokenIds,
    );
    return response.data;
  }
}
