<template>
  <LayerModalFormConfirm
    class="redirect-edition-uri-modal"
    confirmBtnTheme="solid"
    :disabledConfirmBtn="
      validatedRedirectUri == undefined ||
      redirectUriValidationError != undefined ||
      isDoingValidation
    "
    @submit="submit"
    @close="close"
  >
    <div class="redirect-edition-uri-modal__description-section">
      <h4 class="redirect-edition-uri-modal__title">
        {{ title }}
      </h4>

      <Markdown
        class="redirect-edition-uri-modal__redirect-uri-explanation"
        :content="contents.redirectUriExplanation"
      />
    </div>

    <ViewHelpButton
      class="redirect-edition-uri-modal__view-help-button"
      documentationAnchor="#consume-an-api"
    />

    <DvpField
      class="redirect-edition-uri-modal__redirect-uri-field"
      required
      :label="contents.redirectUriField"
      :errorMessage="redirectUriValidationError"
    >
      <MTextInput
        :modelValue="redirectUri"
        :placeholder="contents.redirectUriPlaceholder"
        :isInvalid="redirectUriValidationError != undefined"
        @update:modelValue="updateRedirectUri"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";
import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { debounce } from "@/commons/libs/utils/debouncer";
import { getApplicationRepository } from "@/commons/repositories/libs/get-application-repository";

import contents from "@/dashboard/contents/redirect-uri-edition-modal";

enum ERedirectUriModalEditionMode {
  CREATION = "CREATION",
  UPDATE = "UPDATE",
}

export default {
  components: {
    ViewHelpButton,
    LayerModalFormConfirm,
    MTextInput,
    DvpField,
    Markdown,
  },
  props: {
    initialRedirectUri: {
      type: String,
      default: undefined,
    },
    oauthProvider: {
      type: String,
      required: true,
    },
  },
  emits: ["submit", "onClose"],
  data() {
    return {
      contents,
      redirectUri: undefined, // value of the input. Useful so the input displays the new value when the user types a new character instead of waiting for the validation
      validatedRedirectUri: undefined,
      redirectUriValidationError: undefined,
      isDoingValidation: false,
    };
  },
  computed: {
    editionMode() {
      return this.initialRedirectUri == undefined
        ? ERedirectUriModalEditionMode.CREATION
        : ERedirectUriModalEditionMode.UPDATE;
    },
    title() {
      return this.editionMode === ERedirectUriModalEditionMode.CREATION
        ? contents.titleForCreation
        : contents.titleForUpdate;
    },
  },
  created() {
    this.debouncedValidateRedirectUri = debounce(this.validateRedirectUri);
    this.redirectUri = this.initialRedirectUri;
  },
  methods: {
    updateRedirectUri(newRedirectUri: string): void {
      this.isDoingValidation = true;
      this.redirectUri = newRedirectUri;
      this.debouncedValidateRedirectUri(newRedirectUri);
    },
    async validateRedirectUri(redirectUri: string): Promise<void> {
      const validation = await getApplicationRepository().validateRedirectUri(
        redirectUri,
        this.oauthProvider,
      );
      this.redirectUriValidationError = validation.message;
      this.validatedRedirectUri = redirectUri;
      this.isDoingValidation = false;
    },
    submit(): void {
      this.$emit("submit", this.validatedRedirectUri);
      this.close();
    },
    close(): void {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
.redirect-edition-uri-modal__description-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.redirect-edition-uri-modal__title {
  margin: 0;
}

.redirect-edition-uri-modal__view-help-button {
  align-self: flex-start;
}
</style>
