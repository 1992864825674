<template>
  <div class="contract-card-api-info">
    <div class="contract-card-api-info__identification">
      <div>
        <strong>{{ apiLabel(contract.api) }}</strong>
        <div v-if="contract.api.deprecationDate" class="color-danger">
          {{ contents.deprecatedSince }}
          {{ toLocaleDate(contract.api.deprecationDate) }}
        </div>
      </div>
      <div>
        <Chip class="contract-card-api-info__chip">
          {{ `v${contract.api.version || "1"}` }}
        </Chip>
      </div>
    </div>

    <div class="contract-card-api-info__quota-section">
      <span
        v-if="enabledApiRateLimit != undefined"
        class="contract-card-api-info__quota-info"
        ><img :src="quotaIcon" />
        {{
          contents.apiRateLimit(getRateLimitShortLabel(enabledApiRateLimit))
        }}</span
      >

      <span
        v-if="enabledContractRateLimit != undefined"
        class="contract-card-api-info__quota-info"
        ><img :src="quotaIcon" />{{
          contents.appRateLimit(
            getRateLimitShortLabel(enabledContractRateLimit),
          )
        }}</span
      >

      <span
        v-if="
          enabledApiRateLimit == undefined &&
          enabledContractRateLimit == undefined
        "
        class="contract-card-api-info__quota-info"
        ><img :src="quotaIcon" />{{ contents.noQuota }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
import Chip from "@/commons/components/Chip.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { toLocaleDate } from "@/commons/utils/date-utils";
import { getRateLimitShortLabel } from "@/commons/utils/quota-utils";

import { RateLimit } from "@/commons/domain/models/rate-limit";

import contents from "@/dashboard/contents/contract-item";

export default {
  components: {
    Chip,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      quotaIcon: require("@/commons/assets/icons/icon-quota.svg"),
      tokenActionsPanelIsOpen: false,
    };
  },
  computed: {
    enabledApiRateLimit(): RateLimit | undefined {
      return this.contract.api.rateLimits?.global?.isEnabled
        ? this.contract.api.rateLimits?.global
        : undefined;
    },
    enabledContractRateLimit(): RateLimit | undefined {
      return this.contract.rateLimits?.global?.isEnabled
        ? this.contract.rateLimits?.global
        : undefined;
    },
  },
  methods: {
    apiLabel,
    toLocaleDate,
    getRateLimitShortLabel,
  },
};
</script>

<style lang="scss">
.contract-card-api-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem;
}

.contract-card-api-info__identification {
  display: flex;
  justify-content: space-between;
}

.contract-card-api-info__quota-section {
  display: flex;
  gap: 1rem;
}

.contract-card-api-info__quota-info {
  @include set-text-s;
  display: inline-flex;
  align-items: center;
}

.contract-card-api-info__chip {
  width: 3rem;
}
</style>
