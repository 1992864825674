import { ActionContext } from "vuex";

import { getConfigRepository } from "@/commons/repositories/libs/get-config-repository";

import { State } from "./types";

async function loadEnvironmentsConfig({ commit }: ActionContext<State, any>) {
  commit(
    "setEnvironmentsConfig",
    await getConfigRepository().getEnvironmentsConfig(),
  );
}

export default { loadEnvironmentsConfig };
