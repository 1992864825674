import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractWarning = _resolveComponent("ContractWarning")!

  return (_openBlock(), _createBlock(_component_ContractWarning, {
    class: "contract-warning-failing",
    title: $data.contents.subscriptionFailed($options.apiLabel($props.contract.api)),
    message: $data.contents.message
  }, null, 8, ["title", "message"]))
}