export default {
  modalTitle: "Enter namespace to publish to",
  namespaceLabel: (secretManager) =>
    `Please enter the ${secretManager} namespace name you want to give rights DevPortal to publish your Application API Keys in it:`,

  namespacePlaceholder: (secretManager) => `${secretManager} Namespace`,

  warningMessage: (secretManager) =>
    `To give DevPortal access to your ${secretManager} namespace, please:\n\n- <b>Click</b> on the ${secretManager} link below,\n-Once in ${secretManager}, <b>approve new rights</b>,\n-Close your ${secretManager} tab,\n-Come back here,\n-<b>Confirm rights have been given</b>, by ticking the below checkbox,\n-Click on <b>Publish API Keys</b>.`,

  linkToGiveRights: "Link to enable in MyCloudConsole",

  confirmationText: "I confirm I have given rights to DevPortal",

  submitBtnLabel: "Publish API Keys",

  toastTitle: (secretManager) => `${secretManager} Publication activated!`,
  toastMessage: (secretManager) =>
    `Your Application's API Keys are now published in your ${secretManager} namespace.`,
};
