import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "contract-card-api-info" }
const _hoisted_2 = { class: "contract-card-api-info__identification" }
const _hoisted_3 = {
  key: 0,
  class: "color-danger"
}
const _hoisted_4 = { class: "contract-card-api-info__quota-section" }
const _hoisted_5 = {
  key: 0,
  class: "contract-card-api-info__quota-info"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "contract-card-api-info__quota-info"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 2,
  class: "contract-card-api-info__quota-info"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chip = _resolveComponent("Chip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("strong", null, _toDisplayString($options.apiLabel($props.contract.api)), 1),
        ($props.contract.api.deprecationDate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($data.contents.deprecatedSince) + " " + _toDisplayString($options.toLocaleDate($props.contract.api.deprecationDate)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Chip, { class: "contract-card-api-info__chip" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(`v${$props.contract.api.version || "1"}`), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($options.enabledApiRateLimit != undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createElementVNode("img", { src: $data.quotaIcon }, null, 8, _hoisted_6),
            _createTextVNode(" " + _toDisplayString($data.contents.apiRateLimit($options.getRateLimitShortLabel($options.enabledApiRateLimit))), 1)
          ]))
        : _createCommentVNode("", true),
      ($options.enabledContractRateLimit != undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
            _createElementVNode("img", { src: $data.quotaIcon }, null, 8, _hoisted_8),
            _createTextVNode(_toDisplayString($data.contents.appRateLimit(
            $options.getRateLimitShortLabel($options.enabledContractRateLimit),
          )), 1)
          ]))
        : _createCommentVNode("", true),
      (
          $options.enabledApiRateLimit == undefined &&
          $options.enabledContractRateLimit == undefined
        )
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
            _createElementVNode("img", { src: $data.quotaIcon }, null, 8, _hoisted_10),
            _createTextVNode(_toDisplayString($data.contents.noQuota), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}