<template>
  <ContractWarning
    class="contract-warning-failing"
    :title="contents.subscriptionFailed(apiLabel(contract.api))"
    :message="contents.message"
  />
</template>

<script lang="ts">
import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";

import { EContractStatus } from "@/commons/store/types";

import contents from "@/commons/contents/contract-warning-failling";

export default {
  components: { ContractWarning },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents,
      EContractStatus,
    };
  },
  methods: {
    apiLabel,
  },
};
</script>
