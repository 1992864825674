import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "update-app__fields" }
const _hoisted_2 = {
  key: 0,
  "data-cy": "update-app-for-testing",
  class: "update-app__test-application"
}
const _hoisted_3 = { class: "update-app__form-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MCheckbox = _resolveComponent("MCheckbox")!
  const _component_IconTooltip = _resolveComponent("IconTooltip")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_AutoCompleteSelect = _resolveComponent("AutoCompleteSelect")!
  const _component_MTextArea = _resolveComponent("MTextArea")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createElementBlock("form", {
    autocomplete: "false | random-string",
    class: "update-app",
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($options.user.isInternal)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_MCheckbox, {
              id: "testing-value",
              class: "update-app__test-application-value",
              modelValue: $data.form.forTesting,
              disabled: $options.isLoading,
              label: $data.contents.testApplication,
              "onUpdate:modelValue": $options.changeForTesting
            }, null, 8, ["modelValue", "disabled", "label", "onUpdate:modelValue"]),
            _createVNode(_component_IconTooltip, {
              text: $data.contents.applicationForTestingTooltip,
              iconName: "NotificationQuestion24"
            }, null, 8, ["text"])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_DvpField, {
        "data-cy": "update-app-name",
        class: "update-app__application-name",
        label: $data.contents.applicationName,
        errorMessage: $data.form.firstError('name')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.name) = $event)),
            isInvalid: $options.isInvalidApplicationName
          }, null, 8, ["modelValue", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"]),
      ($data.form.type)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 1,
            "data-cy": "update-app-type",
            class: "update-app__app-type",
            label: $data.contents.applicationType,
            errorMessage: $data.form.firstError('type')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MSelect, {
                id: "update-app__app-type-select",
                modelValue: $data.form.type,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.type) = $event)),
                placeholder: $data.contents.applicationTypeLabel,
                options: $options.typeOptions
              }, null, 8, ["modelValue", "placeholder", "options"])
            ]),
            _: 1
          }, 8, ["label", "errorMessage"]))
        : _createCommentVNode("", true),
      ($options.user.isInternal && !$data.form.forTesting)
        ? (_openBlock(), _createBlock(_component_DvpField, {
            key: 2,
            "data-cy": "update-app-product",
            class: "update-app__product",
            label: $data.contents.relatedProduct,
            errorMessage: $data.form.firstError('product'),
            helpText: $data.contents.applicationProductTooltip
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AutoCompleteSelect, {
                id: "appRelatedProduct",
                modelValue: $data.form.product,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($data.form.product) = $event)),
                disabled: $options.isLoading,
                options: $data.productsList,
                placeholder: $data.contents.applicationProductPlaceholder,
                keyProperty: "id",
                labelProperty: "name",
                onSearchChange: $options.searchTerm
              }, null, 8, ["modelValue", "disabled", "options", "placeholder", "onSearchChange"])
            ]),
            _: 1
          }, 8, ["label", "errorMessage", "helpText"]))
        : _createCommentVNode("", true),
      _createVNode(_component_DvpField, {
        "data-cy": "update-app-description",
        class: "update-app__description",
        label: $data.contents.description,
        errorMessage: $data.form.firstError('description')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextArea, {
            modelValue: $data.form.description,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($data.form.description) = $event)),
            disabled: $options.isLoading,
            placeholder: $data.contents.applicationDescLabel,
            isInvalid: $options.isInvalidDescription
          }, null, 8, ["modelValue", "disabled", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MButton, {
        "data-cy": "cancel-btn",
        type: "button",
        theme: "bordered-neutral",
        label: $data.contents.cancel,
        disabled: $options.isActionButtonDisabled(),
        onClick: $options.cancel
      }, null, 8, ["label", "disabled", "onClick"]),
      _createVNode(_component_MButton, {
        "data-cy": "submit-btn",
        type: "submit",
        label: $data.contents.updateButtonLabel,
        disabled: $options.isActionButtonDisabled()
      }, null, 8, ["label", "disabled"])
    ])
  ], 32))
}