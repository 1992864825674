import { Contract } from "@/commons/domain/models/contract";
import { OAuthToken } from "@/commons/domain/models/oauth-token";
import { Token } from "@/commons/domain/models/token";
import { EOauthFlow } from "@/commons/types/oauth-flow-types";

// TODO Move file into /commons/utils ?

export const SIZE_PER_PAGE_CONSUMERS = 10;

export function isRecommendingTokenRotation(contract: Contract): boolean {
  const contractTokens = Object.values<Token>(contract.tokens);
  return contractTokens.length == 1 && contractTokens[0].isAboutToExpire;
}

export function isOAuthContract(contract: Contract): boolean {
  return Object.values(contract.oAuthTokens).length > 0;
}

export function getContractOauthFlow(
  contract: Contract,
): EOauthFlow | undefined {
  return (
    contract.oAuthTokens && Object.values(contract.oAuthTokens)[0]?.oauthFlow
  );
}

export function getFirstOauthToken(contract: Contract): OAuthToken {
  return contract.oAuthTokens && Object.values(contract.oAuthTokens)[0];
}

export function hasOnlyOneRemainingOAuthContract(contract: Contract): boolean {
  if (isOAuthContract(contract)) {
    const oauthTokens = Object.values(contract.oAuthTokens);

    return (
      1 === oauthTokens.length &&
      !oauthTokens[0].shared &&
      !oauthTokens[0].multiLined
    );
  }
  return false;
}
