import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

import { State } from "./types";

export const state: State = {
  frontendConfig: undefined,
  deployments: [],
};

export default {
  state,
  getters,
  mutations,
  actions,
};
