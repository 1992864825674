<template>
  <div class="contract-card">
    <template v-if="isOauthContract">
      <ContractOAuthCardInfo
        :contract="contract"
        :actionsPanelButtonIsVisible="actionItemsToShow.length > 0"
        @clickOnActionsPanelButton="actionsMenuIsOpen = true"
      />
    </template>
    <template v-else>
      <ContractCardHeader
        :contract="contract"
        :actionsPanelButtonIsVisible="actionItemsToShow.length > 0"
        @clickOnActionsPanelButton="actionsMenuIsOpen = true"
      />

      <ContractCardApiInfo
        class="contract-card__api-info"
        :contract="contract"
      />

      <ContractApiKeysDisplay
        v-model:activeTokenIndex="activeTokenIndex"
        :contract="contract"
      />

      <ContractCardDocumentation
        class="contract-card__documentation"
        :contract="contract"
      />
    </template>

    <ContractCardActionsMenu
      v-if="actionsMenuIsOpen"
      class="contract-card__actions-menu"
      :actionItems="actionItemsToShow"
      :contract="contract"
      :token="activeToken"
      :isOAuthContract="isOauthContract"
      @rotateToken="rotateToken"
      @extendToken="extendToken"
      @deleteToken="openDeleteTokenModal"
      @unsubscribe="openUnsubscriptionModal"
      @clickOnClose="actionsMenuIsOpen = false"
    />
  </div>
</template>

<script lang="ts">
import { markRaw, PropType } from "vue";

import ContractCardHeader from "@/dashboard/views/ContractsList/contractCard/ContractApiKeyCardHeader.vue";
import ContractCardActionsMenu from "@/dashboard/views/ContractsList/contractCard/ContractCardActionsMenu.vue";
import ContractCardApiInfo from "@/dashboard/views/ContractsList/contractCard/ContractCardApiInfo.vue";
import ContractCardDocumentation from "@/dashboard/views/ContractsList/contractCard/ContractCardDocumentation.vue";
import ContractOAuthCardInfo from "@/dashboard/views/ContractsList/contractCard/ContractOAuthCardInfo.vue";
import ContractApiKeysDisplay from "@/dashboard/views/ContractsList/contractCard/tokenDisplay/ContractApiKeysDisplay.vue";
import ContractDeleteTokenModal from "@/dashboard/views/ContractsList/modal/ContractDeleteTokenModal.vue";
import ContractUnsubscriptionModal from "@/dashboard/views/ContractsList/modal/ContractUnsubscriptionModal.vue";

import {
  CONTRACT_CARD_ACTION_ITEMS,
  ContractCardActionItem,
  itemHasExclusionRestriction,
} from "@/dashboard/utils/token-action-utils";

import { Application } from "@/commons/domain/models/application";
import { Contract } from "@/commons/domain/models/contract";
import { OAuthToken } from "@/commons/domain/models/oauth-token";
import { Token } from "@/commons/domain/models/token";

import contentsOfDeleteTokenModal from "@/dashboard/contents/contract-delete-token-modal";
import contentsOfUnsubscriptionModal from "@/dashboard/contents/contract-unsubscription-modal";

export default {
  components: {
    ContractOAuthCardInfo,
    ContractCardHeader,
    ContractCardApiInfo,
    ContractCardDocumentation,
    ContractCardActionsMenu,
    ContractApiKeysDisplay,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
  },
  emits: ["contractChanged"],
  data() {
    return {
      quotaIcon: require("@/commons/assets/icons/icon-quota.svg"),
      actionsMenuIsOpen: false,
      activeTokenIndex: 0,
    };
  },
  computed: {
    application(): Application {
      return this.contract.application;
    },
    isOauthContract(): boolean {
      return Object.keys(this.contract.oAuthTokens).length > 0;
    },
    activeToken(): Token | OAuthToken {
      return this.isOauthContract
        ? Object.values<OAuthToken>(this.contract.oAuthTokens)[
            this.activeTokenIndex
          ]
        : Object.values<Token>(this.contract.tokens)[this.activeTokenIndex];
    },
    actionItemsToShow(): ContractCardActionItem[] {
      const contractAndTokenAllowedActions = {
        ...this.contract.allowedActions,
        ...(this.activeToken?.allowedActions || {}),
      };

      return CONTRACT_CARD_ACTION_ITEMS.filter(
        (item) =>
          contractAndTokenAllowedActions[item.allowedActionName] === true &&
          !itemHasExclusionRestriction(item, this.contract),
      );
    },
    isOAuthContractWithSharedOrMultiLinedToken(): boolean {
      if (this.isOauthContract) {
        const oAuthTokens = Object.values<OAuthToken>(
          this.contract.oAuthTokens,
        );
        return oAuthTokens[0]?.shared || oAuthTokens[0]?.multiLined;
      }
      return false;
    },
  },
  methods: {
    async rotateToken() {
      this.actionsMenuIsOpen = false;
      await this.$store.dispatch("rotateToken", {
        appId: this.application.id,
        contractId: this.contract.id,
        tokenId: this.activeToken.id,
      });
      this.emitContractChanged();
    },
    async extendToken() {
      this.actionsMenuIsOpen = false;
      await this.$store.dispatch("extendToken", {
        appId: this.application.id,
        contractId: this.contract.id,
        tokenId: this.activeToken.id,
      });
      this.emitContractChanged();
    },
    openDeleteTokenModal() {
      this.$store.commit("openLayerModal", {
        title: contentsOfDeleteTokenModal.modalTitle,
        component: markRaw(ContractDeleteTokenModal),
        props: {
          token: this.activeToken,
          contract: this.contract,
        },
        listeners: {
          submitted: () => this.emitContractChanged(),
        },
      });
      this.actionsMenuIsOpen = false;
    },
    openUnsubscriptionModal() {
      this.$store.commit("openLayerModal", {
        title: contentsOfUnsubscriptionModal.modalTitle,
        component: ContractUnsubscriptionModal,
        props: {
          contract: this.contract,
          isOAuthContract: this.isOauthContract,
          isOAuthContractWithSharedOrMultiLinedToken:
            this.isOAuthContractWithSharedOrMultiLinedToken,
        },
        listeners: {
          submitted: () => this.emitContractChanged(),
        },
      });
    },
    emitContractChanged() {
      this.$emit("contractChanged");
    },
  },
};
</script>

<style lang="scss">
.contract-card {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
}

.contract-card__api-info {
  flex: 1;
}

.contract-card__actions-menu {
  position: absolute;
  inset: 0;
}
</style>
