import { State } from "./types";

function frontendConfig(state: State) {
  return state.frontendConfig;
}

function deployments(state: State) {
  return state.deployments;
}

function locationSettings(state: State, getters) {
  return getters.frontendConfig.locationSettings[location.host] != undefined
    ? getters.frontendConfig.locationSettings[location.host]
    : getters.frontendConfig.locationSettings["default"];
}

function supportUrl(state: State, getters) {
  return getters.locationSettings.name != undefined
    ? getters.frontendConfig.supportUrl[getters.locationSettings.name]
    : undefined;
}

function isOnlyAllowingPingLogin(state: State, getters): boolean {
  return Boolean(getters.locationSettings?.isOnlyAllowingPingLogin);
}

function styles(state: State) {
  return state.frontendConfig.styles[location.host]
    ? state.frontendConfig.styles[location.host]
    : state.frontendConfig.styles["default"];
}

function batchDashboardUrl(state: State) {
  return state.frontendConfig.batchDashboardUrl;
}

function batchDocumentationUrl(state: State) {
  return state.frontendConfig.batchDocumentationUrl;
}

export default {
  frontendConfig,
  deployments,
  locationSettings,
  supportUrl,
  styles,
  isOnlyAllowingPingLogin,
  batchDashboardUrl,
  batchDocumentationUrl,
};
