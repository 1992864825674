import { Dictionary } from "@/commons/domain/models/dictionary";
import { Token } from "@/commons/domain/models/token";
import { TokenDto } from "@/commons/dtos/token-dto";

export class TokenMapper {
  static toDomain(tokensDtos: TokenDto[] = []) {
    const tokens: Dictionary<Token> = {};

    for (const tokenDto of tokensDtos) {
      tokens[tokenDto.id] = TokenMapper.toTokenDomain(tokenDto);
    }
    return tokens;
  }
  static toTokenDomain(tokenDto: TokenDto): Token {
    return {
      id: tokenDto.id,
      key: tokenDto.key,
      status: tokenDto.status,
      expireAt: tokenDto.expireAt,
      isAboutToExpire: tokenDto.isAboutToExpire,
      createdAt: tokenDto.createdAt,
      allowedActions: tokenDto.allowedActions,
      hasReachedExtensionLimit: tokenDto.hasReachedExtensionLimit,
    };
  }
}
