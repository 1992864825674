<template>
  <Card class="timeout">
    <div class="timeout__header">
      <h4>{{ contents.timeoutSettings }}</h4>
      <div v-if="apiHasPendingTimeoutRequest" class="timeout__pending-request">
        <MBadge class="timeout__pending-request-badge" type="information">{{
          contents.timeoutPendingRequestLabel
        }}</MBadge>
        <RouterLink
          class="timeout__pending-request-link"
          :to="apiPendingTimeoutRequestRoute"
          ><span class="timeout__pending-request-link-label">{{
            contents.timeoutLinkLabel
          }}</span>
          <MIcon
            name="DisplayExternalLink16"
            class="timeout__pending-request-link-icon"
          ></MIcon
        ></RouterLink>
      </div>
    </div>

    <form autocomplete="false | random-string" @submit.prevent="submit">
      <Stack>
        <p>{{ contents.timeoutDescription }}</p>

        <MNotification
          v-if="apiHasPendingTimeoutRequest"
          class="timeout__limit-warning"
          type="warning"
          :title="contents.timeoutLimitWarningTitle"
        >
          <span
            v-html="
              contents.timeoutLimitWarning(
                apiPendingTimeoutRequest.requestedTimeout,
              )
            "
          />
        </MNotification>
        <MNotification
          v-else-if="
            isApiPublic(currentApi) &&
            currentApi.timeoutInSeconds > MAX_PUBLIC_API_TIMEOUT
          "
          class="timeout__limit-information"
          type="warning"
          :title="contents.loweredTimeoutWarningTitle(MAX_PUBLIC_API_TIMEOUT)"
        >
          <template #default>
            <span
              v-html="contents.loweredTimeoutWarning(MAX_PUBLIC_API_TIMEOUT)"
            />
          </template>
          <template #footer>
            <ViewHelpButton
              documentationAnchor="#specific-timeout-for-internet-exposed-api"
            />
          </template>
        </MNotification>
        <MNotification
          v-else
          class="timeout__limit-information"
          type="information"
          :title="contents.timeoutLimitInformationTitle"
        >
          <span
            v-html="
              contents.timeoutLimitInformation(MAX_API_TIMEOUT_DEFINED_BY_USER)
            "
          />
        </MNotification>

        <MField
          id="timeout-field"
          class="timeout__timeout-field"
          :label="contents.timeout"
          requirementText="required"
          :isInvalid="isInvalidField('timeoutInSeconds')"
          :errorMessage="form.firstError('timeoutInSeconds')"
        >
          <MTextInput
            v-model.number="form.timeoutInSeconds"
            type="number"
            icon="TimeTimeDecline24"
            :min="MIN_API_TIMEOUT"
            :max="MAX_API_TIMEOUT"
            :isInvalid="isInvalidField('timeoutInSeconds')"
            :disabled="apiHasPendingTimeoutRequest"
          />
        </MField>

        <div class="pushed-right">
          <MButton
            class="mc-button--bordered-neutral timeout__cancel-button"
            type="button"
            :label="contents.cancel"
            :disabled="apiHasPendingTimeoutRequest"
            @click="cancel"
          />

          <MButton
            class="timeout__submit-button"
            type="submit"
            :label="submitLabel"
            :disabled="
              !!form.errors.length ||
              timeoutEqualsSavedValue ||
              apiHasPendingTimeoutRequest
            "
          />
        </div>
      </Stack>
    </form>
  </Card>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";
import MField from "@mozaic-ds/vue-3/src/components/field/MField.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";
import { RouteLocationRaw } from "vue-router";

import Card from "@/commons/components/Card.vue";
import Stack from "@/commons/components/Stack.vue";
import ViewHelpButton from "@/commons/components/UserDocumentationLinks/ViewHelpButton.vue";

import RequestTimeoutModal from "./RequestTimeoutModal.vue";

import { isApiPublic } from "@/commons/libs/utils/apiUtils";

import { Api } from "@/commons/domain/models/api";
import { RequestSummary } from "@/commons/domain/models/request-summary";
import {
  MAX_API_TIMEOUT,
  MAX_API_TIMEOUT_DEFINED_BY_USER,
  MAX_PUBLIC_API_TIMEOUT,
  MIN_API_TIMEOUT,
} from "@/manager/domain/forms/timeout";
import { TimeoutForm } from "@/manager/domain/forms/timeout-form";

import { ERequestFilteringVisibility } from "@/commons/store/types";

import requestTimeoutModalContents from "@/manager/contents/request-timeout-modal";
import contents from "@/manager/contents/timeout";

export default {
  name: "Timeout",
  components: {
    Card,
    Stack,
    MField,
    MTextInput,
    MButton,
    MNotification,
    MBadge,
    MIcon,
    ViewHelpButton,
  },
  props: {
    currentApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: TimeoutForm.create(),
      persistedTimeouts: undefined,
      contents,
      MAX_API_TIMEOUT,
      MIN_API_TIMEOUT,
      MAX_API_TIMEOUT_DEFINED_BY_USER,
      MAX_PUBLIC_API_TIMEOUT,
    };
  },
  computed: {
    userIsAdmin(): boolean {
      return this.$store.getters["userIsAdmin"];
    },
    apiHasPendingTimeoutRequest(): boolean {
      return this.$store.getters["apiHasPendingTimeoutRequest"];
    },
    apiPendingTimeoutRequestRoute(): RouteLocationRaw | undefined {
      return this.apiPendingTimeoutRequest
        ? {
            name: "requestManager",
            query: {
              visibility: ERequestFilteringVisibility.SUBMITTED,
              requestId: this.apiPendingTimeoutRequest.id,
            },
          }
        : undefined;
    },
    apiPendingTimeoutRequest(): RequestSummary {
      return this.$store.getters["currentApiPendingTimeoutRequest"];
    },
    timeoutEqualsSavedValue(): boolean {
      return Boolean(
        this.persistedTimeouts?.timeoutInSeconds === this.form.timeoutInSeconds,
      );
    },
    submitLabel(): string {
      return this.needAdminValidation(this.form.timeoutInSeconds)
        ? this.contents.requestLargerTimeout
        : this.contents.applyChanges;
    },
  },
  async mounted() {
    const currentApi: Api = this.currentApi;

    await this.$store.dispatch(
      "fetchPendingTimeoutRequests",
      this.currentApi.id,
    );

    await this.form.init({
      timeoutInSeconds: currentApi.timeoutInSeconds,
    });

    this.persistedTimeouts = this.form.data();
  },
  methods: {
    isApiPublic,
    isInvalidField(fieldName: string): boolean {
      return this.form.firstError(fieldName) != null;
    },
    async cancel(): Promise<void> {
      await this.form.init(this.persistedTimeouts);
    },
    openRequestTimeoutModal(requestedTimeout: number): void {
      this.$store.commit("openLayerModal", {
        title: requestTimeoutModalContents.modalTitle,
        component: RequestTimeoutModal,
        props: {
          api: this.currentApi,
          requestedTimeout,
        },
        listeners: {
          onClose: async () => {
            await this.cancel();
          },
        },
      });
    },

    async submit(): Promise<void> {
      await this.form.validate();

      if (!this.form.errors.length && !this.isSaving) {
        const { timeoutInSeconds } = this.form.data();

        if (this.needAdminValidation(timeoutInSeconds)) {
          this.openRequestTimeoutModal(timeoutInSeconds);
        } else {
          this.persistedTimeouts = this.form.data();

          await this.$store.dispatch("updateApi", {
            id: this.currentApi.id,
            timeout: timeoutInSeconds,
          });
        }
      }
    },
    needAdminValidation(timeoutInSeconds: number): boolean {
      return (
        !this.userIsAdmin && timeoutInSeconds > MAX_API_TIMEOUT_DEFINED_BY_USER
      );
    },
  },
};
</script>

<style lang="scss">
.timeout {
  --grid-layout-item-width: 11rem;
}

.timeout__header {
  display: flex;
  justify-content: space-between;
}

.timeout__header > h4 {
  margin-top: 0;
}

.timeout__pending-request {
  display: flex;
  align-items: baseline;
  margin-top: 4px;
}

.timeout__pending-request-badge {
  margin-right: 1rem;
}

.timeout__pending-request-link {
  display: flex;
  align-items: center;
}

.timeout__pending-request-link-icon {
  margin-left: 0.25rem;
}
</style>
