import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contract-card-actions-menu" }
const _hoisted_2 = {
  key: 0,
  class: "contract-card-actions-menu__oauth-items"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["data-cy"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["data-cy"]
const _hoisted_7 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_MBadge = _resolveComponent("MBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["contract-card-actions-menu__header", { 'contract-card-actions-menu__header--oauth': $props.isOAuthContract }])
    }, [
      _createElementVNode("h6", {
        class: _normalizeClass(["contract-card-actions-menu__header-title", {
          'contract-card-actions-menu__header-title--oauth': $props.isOAuthContract,
        }])
      }, _toDisplayString($data.contents.menuTitle), 3),
      _createVNode(_component_IconButton, {
        class: "contract-card-actions-menu__close-button",
        mozaicIconName: $options.closeIconName,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickOnClose')))
      }, null, 8, ["mozaicIconName"])
    ], 2),
    ($props.isOAuthContract)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.actionItems, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.actionName,
              class: "contract-card-actions-menu__oauth-item-container",
              onClick: ($event: any) => (_ctx.$emit(item.actionName))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["contract-card-actions-menu__oauth-item", item.extraClass])
              }, [
                _createVNode(_component_MIcon, {
                  name: item.iconName
                }, null, 8, ["name"]),
                _createElementVNode("span", {
                  "data-cy": `token-action-${item.actionName}`
                }, _toDisplayString(item.label), 9, _hoisted_4)
              ], 2)
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($props.actionItems, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item.actionName,
            class: _normalizeClass(["contract-card-actions-menu__item", item.extraClass]),
            onClick: ($event: any) => (_ctx.$emit(item.actionName))
          }, [
            (item.actionName === 'extendToken')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  "data-cy": `token-action-${item.actionName}`
                }, _toDisplayString($options.getExtendTokenLabel(item)), 9, _hoisted_6))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  "data-cy": `token-action-${item.actionName}`
                }, _toDisplayString(item.label), 9, _hoisted_7)),
            ($options.actionIsRecommended(item))
              ? (_openBlock(), _createBlock(_component_MBadge, {
                  key: 2,
                  type: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($data.contents.recommended), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ], 10, _hoisted_5))
        }), 128))
  ]))
}