import { FrontendConfig } from "@/commons/domain/models/frontendConfig.type";
import { EnvDto } from "@/commons/dtos/env.dto";

import { State } from "./types";

export function setFrontendConfig(
  state: State,
  frontendConfig: Partial<FrontendConfig>,
) {
  state.frontendConfig = frontendConfig;
}

export function setEnvironmentsConfig(state: State, envDto: EnvDto) {
  state.deployments = envDto.deployments;
}

export default {
  setFrontendConfig,
  setEnvironmentsConfig,
};
