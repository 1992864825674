export const TYPES = {
  VALIDATOR: Symbol.for("VALIDATOR"),
  VALIDATION_ERROR_MAPPER: Symbol.for("VALIDATION_ERROR_MAPPER"),
  SERVICE_HUMAN_READABLE_URL: Symbol.for("SERVICE_HUMAN_READABLE_URL"),
  REQUEST_HANDLER: Symbol.for("REQUEST_HANDLER"),
  API_REPOSITORY: Symbol.for("API_REPOSITORY"),
  ZONE_REPOSITORY: Symbol.for("ZONE_REPOSITORY"),
  PRODUCT_REPOSITORY: Symbol.for("PRODUCT_REPOSITORY"),
  BATCH_REPOSITORY: Symbol.for("BATCH_REPOSITORY"),
  API_DOCUMENTATION_PAGE_REPOSITORY: Symbol.for(
    "API_DOCUMENTATION_PAGE_REPOSITORY",
  ),
  CONTRACT_REPOSITORY: Symbol.for("CONTRACT_REPOSITORY"),
  CONFIG_REPOSITORY: Symbol.for("CONFIG_REPOSITORY"),
  USER_REPOSITORY: Symbol.for("USER_REPOSITORY"),
  APPLICATION_REPOSITORY: Symbol.for("APPLICATION_REPOSITORY"),
  TAG_REPOSITORY: Symbol.for("TAG_REPOSITORY"),
  REQUEST_SUMMARY_REPOSITORY: Symbol.for("REQUEST_SUMMARY_REPOSITORY"),
  GROUP_WITH_USERS_ACLS_REPOSITORY: Symbol.for(
    "GROUP_WITH_USERS_ACLS_REPOSITORY",
  ),
  GATEWAY_REPOSITORY: Symbol.for("GATEWAY_REPOSITORY"),
  SCOPE_REPOSITORY: Symbol.for("SCOPE_REPOSITORY"),
};
