import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "quota-rate-limit-edition-modal__frequency-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_MQuantitySelector = _resolveComponent("MQuantitySelector")!
  const _component_MSelect = _resolveComponent("MSelect")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayerModalForm = _resolveComponent("LayerModalForm")!

  return (_openBlock(), _createBlock(_component_LayerModalForm, {
    class: "quota-rate-limit-edition-modal",
    onSubmit: $options.submit
  }, {
    fields: _withCtx(() => [
      _createVNode(_component_DvpField, {
        label: $data.contents.nbrOfRequest,
        errorMessage: $data.form.firstError('maxRequests'),
        helpText: $data.contents.nbrOfRequestHelpText,
        isInvalid: $options.isInvalidMaxRequests
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            "data-cy": "maxRequests",
            isInvalid: $options.isInvalidMaxRequests,
            modelValue: $data.form.maxRequests,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($data.form.maxRequests = parseInt($event, 10) || 0))
          }, null, 8, ["isInvalid", "modelValue"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage", "helpText", "isInvalid"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DvpField, {
          class: "quota-rate-limit-edition-modal__frequency",
          label: $data.contents.frequency,
          errorMessage: $data.form.firstError('windowLength'),
          isInvalid: $options.isInvalidWindowLength
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MQuantitySelector, {
              "data-cy": "windowLength",
              modelValue: $data.form.windowLength,
              valuemin: 1,
              "onUpdate:modelValue": $options.changeWindowLength,
              onIncrement: $options.changeWindowLength,
              onDecrement: $options.changeWindowLength
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onIncrement", "onDecrement"])
          ]),
          _: 1
        }, 8, ["label", "errorMessage", "isInvalid"]),
        _createVNode(_component_DvpField, {
          class: "quota-rate-limit-edition-modal__unit",
          label: $data.contents.unit,
          errorMessage: $data.form.firstError('windowLengthUnit')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MSelect, {
              id: "select-unit",
              modelValue: $data.form.windowLengthUnit,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.form.windowLengthUnit) = $event)),
              "data-cy": "windowLengthUnit",
              placeholder: $data.contents.unit,
              options: $options.windowLengthUnitOptions
            }, null, 8, ["modelValue", "placeholder", "options"])
          ]),
          _: 1
        }, 8, ["label", "errorMessage"])
      ])
    ]),
    formFooter: _withCtx(() => [
      _createVNode(_component_MButton, {
        type: "button",
        theme: "bordered-neutral",
        label: $data.contents.cancel,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('onClose')))
      }, null, 8, ["label"]),
      _createVNode(_component_MButton, {
        type: "submit",
        label: $data.contents.submit,
        disabled: $data.form.errors.length > 0 || $options.isSaving
      }, null, 8, ["label", "disabled"])
    ]),
    _: 1
  }, 8, ["onSubmit"]))
}