import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "timeout__header" }
const _hoisted_2 = {
  key: 0,
  class: "timeout__pending-request"
}
const _hoisted_3 = { class: "timeout__pending-request-link-label" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "pushed-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_MField = _resolveComponent("MField")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_Stack = _resolveComponent("Stack")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "timeout" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", null, _toDisplayString($data.contents.timeoutSettings), 1),
        ($options.apiHasPendingTimeoutRequest)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_MBadge, {
                class: "timeout__pending-request-badge",
                type: "information"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.contents.timeoutPendingRequestLabel), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_RouterLink, {
                class: "timeout__pending-request-link",
                to: $options.apiPendingTimeoutRequestRoute
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_3, _toDisplayString($data.contents.timeoutLinkLabel), 1),
                  _createVNode(_component_MIcon, {
                    name: "DisplayExternalLink16",
                    class: "timeout__pending-request-link-icon"
                  })
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("form", {
        autocomplete: "false | random-string",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => ($options.submit && $options.submit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_Stack, null, {
          default: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString($data.contents.timeoutDescription), 1),
            ($options.apiHasPendingTimeoutRequest)
              ? (_openBlock(), _createBlock(_component_MNotification, {
                  key: 0,
                  class: "timeout__limit-warning",
                  type: "warning",
                  title: $data.contents.timeoutLimitWarningTitle
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: 
              $data.contents.timeoutLimitWarning(
                $options.apiPendingTimeoutRequest.requestedTimeout,
              )
            
                    }, null, 8, _hoisted_4)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : (
            $options.isApiPublic($props.currentApi) &&
            $props.currentApi.timeoutInSeconds > $data.MAX_PUBLIC_API_TIMEOUT
          )
                ? (_openBlock(), _createBlock(_component_MNotification, {
                    key: 1,
                    class: "timeout__limit-information",
                    type: "warning",
                    title: $data.contents.loweredTimeoutWarningTitle($data.MAX_PUBLIC_API_TIMEOUT)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        innerHTML: $data.contents.loweredTimeoutWarning($data.MAX_PUBLIC_API_TIMEOUT)
                      }, null, 8, _hoisted_5)
                    ]),
                    footer: _withCtx(() => [
                      _createVNode(_component_ViewHelpButton, { documentationAnchor: "#specific-timeout-for-internet-exposed-api" })
                    ]),
                    _: 1
                  }, 8, ["title"]))
                : (_openBlock(), _createBlock(_component_MNotification, {
                    key: 2,
                    class: "timeout__limit-information",
                    type: "information",
                    title: $data.contents.timeoutLimitInformationTitle
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        innerHTML: 
              $data.contents.timeoutLimitInformation($data.MAX_API_TIMEOUT_DEFINED_BY_USER)
            
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 1
                  }, 8, ["title"])),
            _createVNode(_component_MField, {
              id: "timeout-field",
              class: "timeout__timeout-field",
              label: $data.contents.timeout,
              requirementText: "required",
              isInvalid: $options.isInvalidField('timeoutInSeconds'),
              errorMessage: $data.form.firstError('timeoutInSeconds')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_MTextInput, {
                  modelValue: $data.form.timeoutInSeconds,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.timeoutInSeconds) = $event)),
                  modelModifiers: { number: true },
                  type: "number",
                  icon: "TimeTimeDecline24",
                  min: $data.MIN_API_TIMEOUT,
                  max: $data.MAX_API_TIMEOUT,
                  isInvalid: $options.isInvalidField('timeoutInSeconds'),
                  disabled: $options.apiHasPendingTimeoutRequest
                }, null, 8, ["modelValue", "min", "max", "isInvalid", "disabled"])
              ]),
              _: 1
            }, 8, ["label", "isInvalid", "errorMessage"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_MButton, {
                class: "mc-button--bordered-neutral timeout__cancel-button",
                type: "button",
                label: $data.contents.cancel,
                disabled: $options.apiHasPendingTimeoutRequest,
                onClick: $options.cancel
              }, null, 8, ["label", "disabled", "onClick"]),
              _createVNode(_component_MButton, {
                class: "timeout__submit-button",
                type: "submit",
                label: $options.submitLabel,
                disabled: 
              !!$data.form.errors.length ||
              $options.timeoutEqualsSavedValue ||
              $options.apiHasPendingTimeoutRequest
            
              }, null, 8, ["label", "disabled"])
            ])
          ]),
          _: 1
        })
      ], 32)
    ]),
    _: 1
  }))
}