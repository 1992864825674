import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contract-card-header" }
const _hoisted_2 = { class: "contract-card-header__zone-name" }
const _hoisted_3 = { class: "contract-card-header__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MBadge = _resolveComponent("MBadge")!
  const _component_IconButton = _resolveComponent("IconButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($data.contents.kong), 1),
    _createElementVNode("div", _hoisted_3, [
      ($options.showRotateTokenWarning)
        ? (_openBlock(), _createBlock(_component_MBadge, {
            key: 0,
            "data-cy": "rotate-token-warning",
            class: "contract-card-header__rotate-token-warning",
            type: "warning"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.contents.rotateTokenNotification), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($props.actionsPanelButtonIsVisible)
        ? (_openBlock(), _createBlock(_component_IconButton, {
            key: 1,
            class: "contract-card-header__actions-panel-button",
            "data-cy": "token-actions-menu",
            mozaicIconName: "DisplaySetting32",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickOnActionsPanelButton')))
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}