<template>
  <div class="dvp-tooltip">
    <MTooltip
      v-if="disabled === false"
      :id="tooltipId"
      :position="position"
      :label="text"
    >
      <slot />
    </MTooltip>
    <slot v-else />
  </div>
</template>

<script lang="ts">
import MTooltip from "@mozaic-ds/vue-3/src/components/tooltip/MTooltip.vue";

export default {
  components: {
    MTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    tooltipId() {
      return `tooltip-${this.$.uid}`;
    },
  },
};
</script>

<style lang="scss">
.dvp-tooltip {
  display: inline-flex; // Need to be flex otherwise it gets impacted with the default line-height and add some uncontrollable height
}
</style>
