<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.09 18.19"
    width="15px"
  >
    <path
      d="M7.9,3.69V2h8.19v8.19H14.4a1,1,0,0,0,0,2h1.83a1.86,1.86,0,0,0,1.86-1.86V1.86A1.86,1.86,0,0,0,16.23,0H7.76A1.86,1.86,0,0,0,5.9,1.86V3.69a1,1,0,0,0,2,0ZM2,16.19V8h8.17v8.17ZM1.67,6A1.67,1.67,0,0,0,0,7.69v8.83a1.67,1.67,0,0,0,1.67,1.67H10.5a1.67,1.67,0,0,0,1.67-1.67V7.69A1.67,1.67,0,0,0,10.5,6Z"
      style="fill: #999; fill-rule: evenodd"
    />
  </svg>
</template>
