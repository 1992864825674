import { v4 as uuid } from "uuid";

import {
  ENotificationToastType,
  NotificationContent,
  NotificationToast,
  State,
} from "@/commons/store/modules/notification/types";

function postSuccessNotification(
  state: State,
  content: NotificationContent,
): void {
  state.notificationToasts.push(
    buildToastNotification(content, ENotificationToastType.SUCCESS),
  );
}

function postWarningNotification(
  state: State,
  content: NotificationContent,
): void {
  state.notificationToasts.push(
    buildToastNotification(content, ENotificationToastType.WARNING),
  );
}

function postInformationNotification(
  state: State,
  content: NotificationContent,
): void {
  state.notificationToasts.push(
    buildToastNotification(content, ENotificationToastType.INFO),
  );
}

function postErrorNotification(
  state: State,
  content: NotificationContent,
): void {
  state.notificationToasts.push(
    buildToastNotification(content, ENotificationToastType.ERROR),
  );
}

function buildToastNotification(
  content: NotificationContent,
  type: ENotificationToastType,
): NotificationToast {
  return {
    id: uuid(),
    title: content.title,
    message: content.message,
    link: content.link,
    type,
    autoClosable: type === ENotificationToastType.SUCCESS,
  };
}

function removeNotificationToast(state: State, id: string) {
  state.notificationToasts.splice(
    state.notificationToasts.findIndex((toast) => toast.id === id),
    1,
  );
}

export default {
  postSuccessNotification,
  postWarningNotification,
  postInformationNotification,
  postErrorNotification,
  removeNotificationToast,
};
