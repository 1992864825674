import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "application__infos" }
const _hoisted_2 = {
  key: 0,
  class: "application__info"
}
const _hoisted_3 = { class: "application__info-label" }
const _hoisted_4 = { class: "application__info" }
const _hoisted_5 = { class: "application__info-label" }
const _hoisted_6 = {
  key: 1,
  class: "application__info"
}
const _hoisted_7 = { class: "application__info-label" }
const _hoisted_8 = { class: "application__menu" }
const _hoisted_9 = { class: "application__btn-actions gap-1200" }
const _hoisted_10 = ["data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MButton = _resolveComponent("MButton")!
  const _component_LayoutContentHeader = _resolveComponent("LayoutContentHeader")!
  const _component_LayoutSection = _resolveComponent("LayoutSection")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Layout2 = _resolveComponent("Layout2")!

  return (_openBlock(), _createBlock(_component_Layout2, { class: "application" }, {
    default: _withCtx(() => [
      ($options.application)
        ? (_openBlock(), _createBlock(_component_LayoutSection, {
            key: 0,
            class: "application__header",
            background: "primary",
            noVerticalPadding: "",
            fullWidthContent: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LayoutContentHeader, {
                title: $options.application.name,
                previousPageRoute: $data.previousPageRoute
              }, {
                actions: _withCtx(() => [
                  ($options.userIsInternal)
                    ? (_openBlock(), _createBlock(_component_MButton, {
                        key: 0,
                        "data-cy": "application-reporting-button",
                        class: "application__header__reporting-link-button",
                        label: $data.contents.reporting,
                        size: "s",
                        icon: "DisplayExternalLink24",
                        iconPosition: "right",
                        theme: "bordered-neutral",
                        href: $options.datadogLink(),
                        target: "_blank"
                      }, null, 8, ["label", "href"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_MButton, {
                    class: "application__header__contact-owners-button",
                    theme: "bordered",
                    size: "s",
                    label: $data.contents.contactOwners,
                    disabled: $options.applicationHasNoContract,
                    icon: "ContactMail24",
                    iconPosition: "right",
                    onClick: $options.contactOwners
                  }, null, 8, ["label", "disabled", "onClick"])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    ($options.application && $options.application.createdAt)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                          _createElementVNode("span", _hoisted_3, _toDisplayString($data.contents.createdAt) + ": ", 1),
                          _createElementVNode("span", null, _toDisplayString($options.date($options.application.createdAt, "DD/MM/YYYY - HH:mm")), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, _toDisplayString($data.contents.updateAt) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString($options.date($options.application.updatedAt, "DD/MM/YYYY - HH:mm")), 1)
                    ]),
                    ($options.application.product)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString($data.contents.relatedProduct) + ": ", 1),
                          _createVNode(_component_router_link, {
                            to: '/docs/product/' + $options.application.product.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($options.application.product.name), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["title", "previousPageRoute"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_LayoutSection, {
        background: "primary",
        noVerticalPadding: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.btnActions, (btn) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: btn.route,
                  class: _normalizeClass([
              'action',
              btn.route === $options.activeRoute && 'btn-action-active',
            ]),
                  "data-cy": `application-menu-${btn.label.toLowerCase()}`
                }, [
                  _createVNode(_component_router_link, {
                    class: "action-title",
                    to: { name: btn.route, params: { id: $props.id } }
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h6", null, _toDisplayString(btn.label) + " " + _toDisplayString(btn.route === "application" ? `(${$options.totalContractsCount})` : ""), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ], 10, _hoisted_10))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_LayoutSection, {
        class: "application__content",
        background: "secondary"
      }, {
        default: _withCtx(() => [
          ($options.application && $options.application.id === $options.applicationId)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}