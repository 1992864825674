<template>
  <ContractWarning
    class="contract-warning-api-unpublished"
    :title="contents.notAvailable(apiLabel(contract.api))"
  >
    <ContractRemoveButton
      v-if="userHasWriteAccessOnCurrentApplication"
      :contract="contract"
      @contractDeleted="$emit('contractDeleted')"
    />

    <MNotification
      v-if="mustDisplayWarningMessage"
      class="contract-warning-api-unpublished__warning-notification"
      type="warning"
    >
      {{ warningMessage }}
    </MNotification>
  </ContractWarning>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import ContractRemoveButton from "./ContractRemoveButton.vue";
import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import {
  hasOnlyOneRemainingOAuthContract,
  isOAuthContract,
} from "@/commons/libs/utils/contractUtils";

import contents from "@/commons/contents/contract-warning-api-unpublished";

export default {
  components: { MNotification, ContractWarning, ContractRemoveButton },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ["contractDeleted"],
  data() {
    return {
      contents,
    };
  },
  computed: {
    // We should check WRITE access on Contract instead of Application but today there is no way to know if the user has write access on Contract object
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
    mustDisplayWarningMessage() {
      return "" !== this.warningMessage;
    },
    warningMessage() {
      let message = "";

      if (isOAuthContract(this.contract)) {
        if (hasOnlyOneRemainingOAuthContract(this.contract)) {
          message = contents.lastOAuthContract;
        }
      } else {
        message = contents.apiKeyWillBeDeleted;
      }

      return message;
    },
  },
  methods: { apiLabel },
};
</script>
<style lang="scss">
.contract-warning-api-unpublished__warning-notification {
  margin-top: 1rem;
  text-align: left;
}
</style>
