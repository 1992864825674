import { EApplicationTab } from "@/commons/store/types";

const tabTypesMap = new Map<EApplicationTab, string>([
  [EApplicationTab.API_KEY, "apikey"],
  [EApplicationTab.OAUTH_CCF, "ccf"],
  [EApplicationTab.OAUTH_ACF, "acf"],
]);

export const getTabTypeFromUrlQuery = (type: string): EApplicationTab => {
  for (const [key, value] of tabTypesMap.entries()) {
    if (value === type) {
      return key;
    }
  }
  return undefined;
};

export const getUrlQueryFromTabType = (type: EApplicationTab): string => {
  return tabTypesMap.get(type);
};

export const applicationHasContractsOfType = (
  type: EApplicationTab,
  contractsMetrics: { apiKey: number; oauthCCF: number; oauthACF: number },
): boolean => {
  switch (type) {
    case EApplicationTab.API_KEY:
      return contractsMetrics?.apiKey > 0;
    case EApplicationTab.OAUTH_CCF:
      return contractsMetrics?.oauthCCF > 0;
    case EApplicationTab.OAUTH_ACF:
      return contractsMetrics?.oauthACF > 0;
    default:
      return false;
  }
};
