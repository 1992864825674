import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "contract-api-keys-display" }
const _hoisted_2 = { class: "contract-api-keys-display__tabs-menu" }
const _hoisted_3 = ["data-status", "onClick"]
const _hoisted_4 = { "data-cy": "token-expiration-date" }
const _hoisted_5 = ["data-status"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractSecret = _resolveComponent("ContractSecret")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.tokens, (token, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["contract-api-keys-display__tab", 
          $props.activeTokenIndex === index
            ? 'contract-api-keys-display__tab--active'
            : 'contract-api-keys-display__tab--inactive'
        ]),
          "data-status": $options.getTokenStyleStatus(token),
          onClick: ($event: any) => (_ctx.$emit('update:activeTokenIndex', index))
        }, [
          _createElementVNode("span", null, _toDisplayString(index === 0 ? $data.contents.currentApiKey : $data.contents.newApiKey), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString($options.getMessageText(token)), 1)
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "contract-api-keys-display__tab-content",
      "data-status": $options.getTokenStyleStatus($options.tokens[$props.activeTokenIndex])
    }, [
      _createVNode(_component_ContractSecret, {
        secret: $options.tokens[$props.activeTokenIndex].key
      }, null, 8, ["secret"])
    ], 8, _hoisted_5)
  ]))
}