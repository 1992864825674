<script setup lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { computed, markRaw, PropType } from "vue";
import { useStore } from "vuex";

import CardContainer from "@/commons/components/CardContainer.vue";
import Divider from "@/commons/components/Divider.vue";
import DvpToggle from "@/commons/components/DvpToggle.vue";
import ActivateSecretSynchronizationModalLayer from "@/dashboard/views/AppDetails/ActivateSecretSynchronizationModalLayer.vue";
import SecretPath from "@/dashboard/views/AppDetails/SecretPath.vue";

import { Application } from "@/commons/domain/models/application";
import { SecretSynchronization } from "@/commons/domain/models/secret-synchronization";

import contentsOfActivateSecretSynchronizationModalLayer from "@/dashboard/contents/activate-secret-synchronization-modal-layer";
import contents from "@/dashboard/contents/secret-synchronization-card";

const props = defineProps({
  application: {
    type: Object as PropType<Application>,
    required: true,
  },
});

const store = useStore();

const secretData = computed((): SecretSynchronization => {
  return props.application.secretSynchronization;
});

const secretManager = computed((): string => {
  return secretData.value?.manager || "Vault";
});

const hasSecretSynchronizationData = computed((): boolean => {
  return secretData.value != undefined;
});

const secretSyncIsEnabled = computed((): boolean => {
  return secretData.value?.isEnabled || false;
});

const userHasWriteAccessOnApplication = computed((): boolean => {
  return store.getters["userHasWriteAccessOnCurrentApplication"];
});

const onToggleClick = () => {
  if (secretSyncIsEnabled.value === false) {
    openActivateSecretSynchronizationModal();
  }
};

const openActivateSecretSynchronizationModal = () => {
  store.commit("openLayerModal", {
    title: contentsOfActivateSecretSynchronizationModalLayer.modalTitle,
    component: markRaw(ActivateSecretSynchronizationModalLayer),
    props: {
      applicationId: props.application.id,
      secretManager: secretManager.value,
    },
  });
};

const frontendConfig = computed(() => {
  return store.getters["config/frontendConfig"];
});

const deactivateSecretFeatureIsEnabled = computed((): boolean => {
  return frontendConfig.value?.secretSynchronization?.deactivate == true;
});
</script>

<template>
  <CardContainer class="secret-synchronization-card">
    <div class="secret-synchronization-card__status-badge">
      <MBadge v-if="secretSyncIsEnabled" type="success">
        {{ contents.enabledBadgeLabel }}</MBadge
      >
      <MBadge v-else type="neutral"> {{ contents.disabledBadgeLabel }}</MBadge>
    </div>

    <div class="secret-synchronization-card__content">
      <h4 class="secret-synchronization-card__title">
        {{ contents.title(secretManager) }}
      </h4>

      <span>{{ contents.subtitle }}</span>

      <div class="secret-synchronization-card__toggle-container">
        <span>{{ contents.activateToggleLabel }}</span>

        <DvpToggle
          :checked="secretSyncIsEnabled"
          :disabled="
            !userHasWriteAccessOnApplication ||
            (secretSyncIsEnabled && !deactivateSecretFeatureIsEnabled)
          "
          @click="onToggleClick"
        />
      </div>
    </div>

    <div
      v-if="hasSecretSynchronizationData"
      class="secret-synchronization-card__secret-data"
    >
      <Divider />

      <SecretPath
        :secretPath="secretData?.secretDisplayPath"
        :copyUrl="secretData.secretUrl"
      />
    </div>
  </CardContainer>
</template>

<style lang="scss">
.secret-synchronization-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.secret-synchronization-card__status-badge {
  align-self: flex-end;
}

.secret-synchronization-card__content {
  display: flex;
  flex-direction: column;
  gap: var(--base-spacing);
}

.secret-synchronization-card__toggle-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.5rem;

  padding: 0 1.5rem;

  background-color: var(--color-background-secondary-light);
  border-radius: var(--m-border-radius-medium);
}

.secret-synchronization-card__title {
  margin: 0;
}

.secret-synchronization-card__secret-data {
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 0.5rem;
  gap: 1rem;
}

.secret-synchronization-card__secret-path {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
