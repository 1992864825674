module.exports = {
  warning: "Be aware that this action cannot be undone",
  prompt: (applicationName) =>
    `If you really want to proceed, type <b>${applicationName}</b>, then confirm.`,
  cancel: "Cancel",
  confirm: "Confirm",
  modalTitle: (applicationName) => `You are about to delete ${applicationName}`,
  deleteApplicationLabel: "Type your application name",
  credentialsWarningTitle: "Credentials will be deleted",
  credentialsWarningMessage:
    "All your credentials, including API Keys and OAuth ClientIDs, will be fully deleted (after a safety deactivation period for ClientIDs). If you use them elsewhere than this application, you will be impacted.",
};
