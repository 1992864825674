import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "application-first-redirect-uri-modal__description-section" }
const _hoisted_2 = { class: "application-first-redirect-uri-modal__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepperApiSubscription = _resolveComponent("StepperApiSubscription")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_ViewHelpButton = _resolveComponent("ViewHelpButton")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "application-first-redirect-uri-modal",
    confirmBtnTheme: "solid",
    disabledConfirmBtn: 
      $data.validatedRedirectUri == undefined ||
      $data.redirectUriValidationError != undefined ||
      $data.isDoingValidation
    ,
    confirmBtnLabel: $options.confirmBtnLabel,
    onSubmit: $options.submit,
    onClose: $options.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_StepperApiSubscription, {
        stepperDescription: $data.contents.stepperDescription,
        stepperSteps: $options.stepperSteps
      }, null, 8, ["stepperDescription", "stepperSteps"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString($data.contents.title), 1),
        _createVNode(_component_Markdown, {
          class: "application-first-redirect-uri-modal__description",
          content: $data.contents.description
        }, null, 8, ["content"])
      ]),
      _createVNode(_component_ViewHelpButton, {
        class: "application-first-redirect-uri-modal__view-help-button",
        documentationAnchor: "#consume-an-api"
      }),
      _createVNode(_component_DvpField, {
        class: "application-first-redirect-uri-modal__redirect-uri-field",
        required: "",
        label: $data.contents.redirectUriField,
        errorMessage: $data.redirectUriValidationError
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.redirectUri,
            placeholder: $data.contents.redirectUriPlaceholder,
            isInvalid: $data.redirectUriValidationError != undefined,
            "onUpdate:modelValue": $options.updateRedirectUri
          }, null, 8, ["modelValue", "placeholder", "isInvalid", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["label", "errorMessage"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "confirmBtnLabel", "onSubmit", "onClose"]))
}