export default {
  enabledBadgeLabel: "Enabled",
  disabledBadgeLabel: "Disabled",

  title: (secretManager: string) => `Publish API Keys to ${secretManager}`,
  subtitle:
    "By activating this feature, your Application Vault namespace will be completed with new paths containing the API Keys.",

  activateToggleLabel: "Activate",
};
