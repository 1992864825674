module.exports = {
  aclManagerSearchLabel: "Search groups",
  aclManagerSearchPlaceholder: "Group name",
  aclManagerListHeader: "Group(s) list",
  aclManagerListEmptyStateMessage: "You need to add group(s) in this part",
  addPartnersCanAccess: "Add all the partners who can access the API",
  alerteMessagePartnersRightsRemoved:
    "Please notice that changing the access restriction your partners access rights will be removed",
  alerteMessageAdministratorApproval:
    "Please notice that updates to Public restriction will be submitted for approval to a platform administrator",
  alertMessageAddPartners:
    "You need to add partners before saving your access restriction",
  alertMessageRequestPending:
    "Your request is pending. Please wait for the administrator's response or modify your access restriction choices",
  no: "No",
  numberOne: "1",
  numberTwo: "2",
  numberThree: "3",
  titleAccessRestrictions: "Access restriction",
  titleStepOne: "Who can subscribe and see your API in the devportal",
  titleStepTwoGroups: "Add partner groups who can access the API",
  titleStepTwoValidateAccess: "Do you want to validate access to your API?",
  tooltipStepThree:
    "Be aware that the partners have to authenticate once in the devportal to be selected",
  validateAccessPreferences: "Validate your access preferences",
  viewYourRequest: "View your request",
  yes: "Yes",
  alertMessagePrivate:
    "You need to add group(s) in this part. Feel free to use the field below to find the group(s)",
  alertMessagePartner:
    "You can only add groups containing at least one partner or external user. Feel free to use the field under to find the group(s)",
  removeGroupAccessModalTitle: "Are you sure you want to remove group access?",
  removeGroupAccessModalText: "Group will have no longer access to this API",
};
