<template>
  <ContractWarning
    class="contract-warning-pending-deployment"
    :title="title"
    :message="contents.message"
    iconName="TimeRead24"
  />
</template>

<script lang="ts">
import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";

import { EContractStatus } from "@/commons/store/types";

import contents from "@/commons/contents/contract-warning-pending-deployment";

export default {
  components: { ContractWarning },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EContractStatus,
      contents,
    };
  },
  computed: {
    title() {
      return this.contract.datacenter
        ? this.contents.subscriptionApiLabelDatacenter(
            apiLabel(this.contract.api),
            this.contract.datacenter,
          )
        : this.contents.subscriptionApiLabel(apiLabel(this.contract.api));
    },
  },
};
</script>
