<template>
  <div class="contract-card-header">
    <div class="contract-card-header__zone-name">
      {{ contents.kong }}
    </div>
    <div class="contract-card-header__right">
      <MBadge
        v-if="showRotateTokenWarning"
        data-cy="rotate-token-warning"
        class="contract-card-header__rotate-token-warning"
        type="warning"
      >
        {{ contents.rotateTokenNotification }}
      </MBadge>
      <IconButton
        v-if="actionsPanelButtonIsVisible"
        class="contract-card-header__actions-panel-button"
        data-cy="token-actions-menu"
        mozaicIconName="DisplaySetting32"
        @click="$emit('clickOnActionsPanelButton')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MBadge from "@mozaic-ds/vue-3/src/components/badge/MBadge.vue";
import { PropType } from "vue";

import IconButton from "@/commons/components/IconButton.vue";

import { isRecommendingTokenRotation } from "@/commons/libs/utils/contractUtils";

import { Contract } from "@/commons/domain/models/contract";
import { Zone } from "@/commons/domain/models/zone";

import { EContractStatus } from "@/commons/store/types";

import contents from "@/dashboard/contents/contract-item";

export default {
  components: {
    MBadge,
    IconButton,
  },
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true,
    },
    actionsPanelButtonIsVisible: {
      type: Boolean,
      default: undefined,
    },
  },
  emits: ["clickOnActionsPanelButton"],
  data() {
    return {
      contents,
      EContractStatus,
    };
  },
  computed: {
    zones(): Zone[] {
      return this.$store.getters["zones"];
    },
    showRotateTokenWarning(): boolean {
      return isRecommendingTokenRotation(this.contract);
    },
  },
};
</script>

<style lang="scss">
.contract-card-header {
  display: flex;
  justify-content: space-between;
}

.contract-card-header__zone-name {
  display: flex;
  align-self: flex-start;
  padding: 0.5rem;
  color: white;
  background-color: var(--color-primary);
  border-top-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.contract-card-header__right {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
}

.contract-card-header__actions-panel-button {
  height: auto;
}

.contract-card-header__rotate-token-warning {
  cursor: default;
}
</style>
