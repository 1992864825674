<template>
  <LayerModalFormConfirm
    class="delete-application-modal"
    :disabledConfirmBtn="!!form.errors.length || isSaving"
    @submit="submit"
    @close="$emit('onClose')"
  >
    <h4>
      {{ contents.modalTitle(applicationName) }}
    </h4>
    <p class="delete-application-modal__undone-action-warning">
      {{ contents.warning }}
    </p>

    <MNotification type="warning" :title="contents.credentialsWarningTitle">
      <span> {{ contents.credentialsWarningMessage }} </span>
    </MNotification>

    <p v-html="contents.prompt(applicationName)" />

    <DvpField :label="contents.deleteApplicationLabel">
      <MTextInput
        v-model="form.text"
        :placeholder="applicationName"
        :isInvalid="isInvalidApplicationName"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";

import contents from "@/dashboard/contents/delete-application-modal";

export default {
  name: "DeleteApplicationModal",
  components: {
    LayerModalFormConfirm,
    DvpField,
    MTextInput,
    MNotification,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    applicationName: {
      type: String,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: ConfirmActionForm.create(),
    };
  },
  computed: {
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    isInvalidApplicationName() {
      return this.form.firstError("text") != null;
    },
  },
  async mounted() {
    await this.form.init({ confirmationText: this.applicationName });
  },
  methods: {
    async submit() {
      await this.form.validate();

      if (!this.isSaving && !this.form.errors.length) {
        await this.$store.dispatch("removeApplication", this.applicationId);
      }
    },
  },
};
</script>

<style>
.delete-application-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--base-spacing);
  height: 100%;
}

.delete-application-modal__undone-action-warning {
  color: var(--color-status-danger);
}
</style>
