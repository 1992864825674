<template>
  <div class="card-listed" :class="{ 'card-listed--no-margin': noMargin }">
    <div class="card-listed__body"><slot /></div>
    <slot name="footer" />
  </div>
</template>

<script lang="ts">
export default {
  props: {
    noMargin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.card-listed {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border: var(--border-default);
  border-radius: 0.5rem;
}

.card-listed__body {
  flex-grow: 1;
  padding: 1rem;
}

.card-listed.card-listed--no-margin {
  .card-listed__body {
    padding: 0;
  }
}
</style>
