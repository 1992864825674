import {
  IsBoolean,
  IsIn,
  IsInt,
  IsNotEmpty,
  Min,
  Validate,
  ValidateIf,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from "class-validator";

import { FormData } from "@/commons/domain/forms/form-data";
import { FormFactory } from "@/commons/domain/forms/form-factory";

function getLimitMaxRequests() {
  return 20000;
}

@ValidatorConstraint({
  name: "InAllowedWindowLengthUnits",
  async: false,
})
export class InAllowedWindowLengthUnits
  implements ValidatorConstraintInterface
{
  validate(value: string, args: ValidationArguments) {
    return (args.object as RateLimitForm).allowedWindowLengthUnits.includes(
      value,
    );
  }
  defaultMessage(args: ValidationArguments) {
    return `Allowed values in "${(
      args.object as RateLimitForm
    ).allowedWindowLengthUnits.join(", ")}"`;
  }
}

@ValidatorConstraint({
  name: "MaxConditionalToWindowLengthUnit",
  async: false,
})
export class MaxConditionalToWindowLengthUnit
  implements ValidatorConstraintInterface
{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(value: string, args: ValidationArguments) {
    const maxRequests = parseInt(value, 10);
    const limitMaxRequests = getLimitMaxRequests();

    return maxRequests <= limitMaxRequests;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultMessage(args: ValidationArguments) {
    return `The maximum value is ${getLimitMaxRequests()}`;
  }
}

@ValidatorConstraint({
  name: "IsRegularExpression",
  async: false,
})
export class IsRegularExpression implements ValidatorConstraintInterface {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(value: string, args: ValidationArguments) {
    try {
      RegExp(value);
      return true;
    } catch {
      return false;
    }
  }
  defaultMessage() {
    return "It's not a valid regular expression";
  }
}

export class RateLimitForm extends FormData {
  id: string;

  isSpecificRateLimit: boolean;

  allowedWindowLengthUnits: string[];

  @IsBoolean()
  isEnabled: boolean;

  @ValidateIf((form) => form.isSpecificRateLimit)
  @IsIn(["GET", "POST", "DELETE", "PUT", "PATCH"])
  httpMethod: string;

  @ValidateIf((form) => form.isSpecificRateLimit)
  @IsNotEmpty()
  @Validate(IsRegularExpression)
  pathRegex: string;

  @IsInt({ message: "This value should be an integer" })
  @Min(1, { message: "The minimum value is 1" })
  @Validate(MaxConditionalToWindowLengthUnit)
  maxRequests: number;

  @IsInt({ message: "This value should be an integer" })
  @Min(1, { message: "The minimum value is 1" })
  windowLength: number;

  @Validate(InAllowedWindowLengthUnits)
  windowLengthUnit: string;

  constructor(
    allowedWindowLengthUnits: string[],
    isSpecificRateLimit: boolean,
  ) {
    super();
    this.allowedWindowLengthUnits = allowedWindowLengthUnits;
    this.isSpecificRateLimit = isSpecificRateLimit;
  }

  $clear() {
    this.isEnabled = false;

    for (const key of ["maxRequests", "windowLength"]) {
      this[key] = 1;
    }

    for (const key of ["httpMethod", "pathRegex", "windowLengthUnit"]) {
      this[key] = "";
    }
  }

  static create(
    allowedWindowLengthUnits: string[],
    isSpecificRateLimit: boolean = false,
  ) {
    return FormFactory.createForm(
      new RateLimitForm(allowedWindowLengthUnits, isSpecificRateLimit),
    );
  }
}
