<template>
  <ContractWarning
    class="contract-warning-disabled"
    :title="contents.subrscribeDisabled(apiLabel(contract.api))"
    :message="contents.message"
  >
    <ContractRemoveButton
      v-if="userHasWriteAccessOnCurrentApplication"
      :contract="contract"
      @contractDeleted="$emit('contractDeleted')"
    />

    <MNotification
      v-if="hasOnlyOneRemainingOAuthContract(contract)"
      class="contract-disabled__warning-notification"
      type="warning"
    >
      {{ contents.additionalWarningMessage }}
    </MNotification>
  </ContractWarning>
</template>

<script lang="ts">
import MNotification from "@mozaic-ds/vue-3/src/components/notification/MNotification.vue";

import ContractRemoveButton from "@/dashboard/views/ContractsList/contractWarning/ContractRemoveButton.vue";

import ContractWarning from "./ContractWarning.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { hasOnlyOneRemainingOAuthContract } from "@/commons/libs/utils/contractUtils";

import { EContractStatus } from "@/commons/store/types";

import contents from "@/commons/contents/contract-warning-disabled";

export default {
  components: {
    MNotification,
    ContractWarning,
    ContractRemoveButton,
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
  emits: ["contractDeleted"],
  data() {
    return {
      contents,
      EContractStatus,
    };
  },
  computed: {
    // We should check WRITE access on Contract instead of Application but today there is no way to know if the user has write access on Contract object
    userHasWriteAccessOnCurrentApplication() {
      return this.$store.getters["userHasWriteAccessOnCurrentApplication"];
    },
  },
  methods: { apiLabel, hasOnlyOneRemainingOAuthContract },
};
</script>

<style lang="scss">
.contract-disabled__warning-notification {
  margin-top: 1rem;
  text-align: left;
}
</style>
