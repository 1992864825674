import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "delete-application-modal__undone-action-warning" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MNotification = _resolveComponent("MNotification")!
  const _component_MTextInput = _resolveComponent("MTextInput")!
  const _component_DvpField = _resolveComponent("DvpField")!
  const _component_LayerModalFormConfirm = _resolveComponent("LayerModalFormConfirm")!

  return (_openBlock(), _createBlock(_component_LayerModalFormConfirm, {
    class: "delete-application-modal",
    disabledConfirmBtn: !!$data.form.errors.length || $options.isSaving,
    onSubmit: $options.submit,
    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onClose')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h4", null, _toDisplayString($data.contents.modalTitle($props.applicationName)), 1),
      _createElementVNode("p", _hoisted_1, _toDisplayString($data.contents.warning), 1),
      _createVNode(_component_MNotification, {
        type: "warning",
        title: $data.contents.credentialsWarningTitle
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString($data.contents.credentialsWarningMessage), 1)
        ]),
        _: 1
      }, 8, ["title"]),
      _createElementVNode("p", {
        innerHTML: $data.contents.prompt($props.applicationName)
      }, null, 8, _hoisted_2),
      _createVNode(_component_DvpField, {
        label: $data.contents.deleteApplicationLabel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_MTextInput, {
            modelValue: $data.form.text,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.form.text) = $event)),
            placeholder: $props.applicationName,
            isInvalid: $options.isInvalidApplicationName
          }, null, 8, ["modelValue", "placeholder", "isInvalid"])
        ]),
        _: 1
      }, 8, ["label"])
    ]),
    _: 1
  }, 8, ["disabledConfirmBtn", "onSubmit"]))
}