import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contract-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractOAuthCardInfo = _resolveComponent("ContractOAuthCardInfo")!
  const _component_ContractCardHeader = _resolveComponent("ContractCardHeader")!
  const _component_ContractCardApiInfo = _resolveComponent("ContractCardApiInfo")!
  const _component_ContractApiKeysDisplay = _resolveComponent("ContractApiKeysDisplay")!
  const _component_ContractCardDocumentation = _resolveComponent("ContractCardDocumentation")!
  const _component_ContractCardActionsMenu = _resolveComponent("ContractCardActionsMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($options.isOauthContract)
      ? (_openBlock(), _createBlock(_component_ContractOAuthCardInfo, {
          key: 0,
          contract: $props.contract,
          actionsPanelButtonIsVisible: $options.actionItemsToShow.length > 0,
          onClickOnActionsPanelButton: _cache[0] || (_cache[0] = ($event: any) => ($data.actionsMenuIsOpen = true))
        }, null, 8, ["contract", "actionsPanelButtonIsVisible"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_ContractCardHeader, {
            contract: $props.contract,
            actionsPanelButtonIsVisible: $options.actionItemsToShow.length > 0,
            onClickOnActionsPanelButton: _cache[1] || (_cache[1] = ($event: any) => ($data.actionsMenuIsOpen = true))
          }, null, 8, ["contract", "actionsPanelButtonIsVisible"]),
          _createVNode(_component_ContractCardApiInfo, {
            class: "contract-card__api-info",
            contract: $props.contract
          }, null, 8, ["contract"]),
          _createVNode(_component_ContractApiKeysDisplay, {
            activeTokenIndex: $data.activeTokenIndex,
            "onUpdate:activeTokenIndex": _cache[2] || (_cache[2] = ($event: any) => (($data.activeTokenIndex) = $event)),
            contract: $props.contract
          }, null, 8, ["activeTokenIndex", "contract"]),
          _createVNode(_component_ContractCardDocumentation, {
            class: "contract-card__documentation",
            contract: $props.contract
          }, null, 8, ["contract"])
        ], 64)),
    ($data.actionsMenuIsOpen)
      ? (_openBlock(), _createBlock(_component_ContractCardActionsMenu, {
          key: 2,
          class: "contract-card__actions-menu",
          actionItems: $options.actionItemsToShow,
          contract: $props.contract,
          token: $options.activeToken,
          isOAuthContract: $options.isOauthContract,
          onRotateToken: $options.rotateToken,
          onExtendToken: $options.extendToken,
          onDeleteToken: $options.openDeleteTokenModal,
          onUnsubscribe: $options.openUnsubscriptionModal,
          onClickOnClose: _cache[3] || (_cache[3] = ($event: any) => ($data.actionsMenuIsOpen = false))
        }, null, 8, ["actionItems", "contract", "token", "isOAuthContract", "onRotateToken", "onExtendToken", "onDeleteToken", "onUnsubscribe"]))
      : _createCommentVNode("", true)
  ]))
}