<template>
  <div class="contract-oauth-card-info">
    <div class="contract-oauth-card-info__content">
      <div class="contract-oauth-card-info__header">
        <div class="contract-oauth-card-info__header-left">
          <strong>{{ apiLabel(contract.api) }}</strong>
          <div
            v-if="contract.api.deprecationDate"
            class="contract-oauth-card-info__deprecated-subtitle"
          >
            {{ contents.deprecatedSince }}
            {{ toLocaleDate(contract.api.deprecationDate) }}
          </div>
        </div>
        <div class="contract-oauth-card-info__header-right">
          <RouterLink
            :to="{
              name: 'apiDocumentationDetail',
              params: { id: contract.api.id },
            }"
            target="_blank"
          >
            <IconButton mozaicIconName="BookStore24" />
          </RouterLink>
          <IconButton
            v-if="actionsPanelButtonIsVisible"
            data-cy="token-actions-menu"
            mozaicIconName="DisplaySetting24"
            @click="emit('clickOnActionsPanelButton')"
          />
        </div>
      </div>
      <div class="contract-oauth-card-info__flags">
        <MFlag
          v-for="flag in contractFlags"
          :key="flag.label"
          :label="flag.label"
          :theme="flag.theme"
        />
      </div>
    </div>
    <div class="contract-oauth-card-info__footer">
      <div class="contract-oauth-card-info__quota">
        <span class="contract-oauth-card-info__quota-info"
          ><MIcon name="Gauge24" />{{ quotaDisplay }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MFlag from "@mozaic-ds/vue-3/src/components/flag/MFlag.vue";
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import { computed, PropType } from "vue";

import IconButton from "@/commons/components/IconButton.vue";

import { apiLabel } from "@/commons/libs/utils/apiUtils";
import { toLocaleDate } from "@/commons/utils/date-utils";
import { getRateLimitShortLabel } from "@/commons/utils/quota-utils";

import { Contract } from "@/commons/domain/models/contract";
import { RateLimit } from "@/commons/domain/models/rate-limit";

import contents from "@/dashboard/contents/contract-item";

const props = defineProps({
  contract: {
    type: Object as PropType<Contract>,
    required: true,
  },
  actionsPanelButtonIsVisible: {
    type: Boolean,
    default: undefined,
  },
});

const emit = defineEmits(["clickOnActionsPanelButton"]);

const hasDeprecationDate = computed(
  () => props.contract?.api?.deprecationDate != undefined,
);

const apiVersion = computed(() => props.contract?.api?.version);

const contractFlags = computed(() => {
  return [
    {
      label: hasDeprecationDate.value
        ? contents.deprecatedVersion(apiVersion.value)
        : `V${apiVersion.value}`,
      theme: hasDeprecationDate.value ? "solid-danger" : "bordered-dark",
    },
    {
      label: contents.flagKongGateway,
      theme: "bordered-dark",
    },
    {
      label: contents.flagOAuthPing,
      theme: "bordered",
    },
  ];
});

const enabledApiRateLimit = computed((): RateLimit | undefined => {
  return props.contract.api.rateLimits?.global?.isEnabled
    ? props.contract.api.rateLimits?.global
    : undefined;
});

const enabledContractRateLimit = computed((): RateLimit | undefined => {
  return props.contract.rateLimits?.global?.isEnabled
    ? props.contract.rateLimits?.global
    : undefined;
});

const quotaDisplay = computed(() => {
  if (enabledApiRateLimit.value != undefined) {
    return contents.apiRateLimit(
      getRateLimitShortLabel(enabledApiRateLimit.value),
    );
  } else if (enabledContractRateLimit.value != undefined) {
    return contents.appRateLimit(
      getRateLimitShortLabel(enabledContractRateLimit.value),
    );
  } else {
    return contents.noQuota;
  }
});
</script>

<style lang="scss">
.contract-oauth-card-info {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;

  padding: 1.5rem;
}
.contract-oauth-card-info__content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.contract-oauth-card-info__header {
  display: flex;
  justify-content: space-between;
}

.contract-oauth-card-info__header-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contract-oauth-card-info__header-right {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
}

.contract-oauth-card-info__deprecated-subtitle {
  font-size: 12px;
  color: var(--color-status-danger);
}

.contract-oauth-card-info__flags {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}

.contract-oauth-card-info__quota-info {
  @include set-text-m;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
}
</style>
