<template>
  <LayerModalFormConfirm
    class="switch-api-deprecation-modal"
    :disabledConfirmBtn="!!form.errors.length || isSaving"
    @submit="submit"
    @close="$emit('onClose')"
  >
    <h4>{{ title }}</h4>
    <Markdown :content="contents.prompt(currentApiName)" />

    <DvpField :label="contents.confirmApiNameLabel">
      <MTextInput
        v-model="form.text"
        :placeholder="currentApiName"
        :isInvalid="isInvalidConfirmationApiName"
      />
    </DvpField>
  </LayerModalFormConfirm>
</template>

<script lang="ts">
import MTextInput from "@mozaic-ds/vue-3/src/components/textinput/MTextInput.vue";

import DvpField from "@/commons/components/form/DvpField.vue";
import LayerModalFormConfirm from "@/commons/components/form/LayerModalFormConfirm.vue";
import Markdown from "@/documentation/components/commons/Markdown.vue";

import { getApiLabelWithVersion } from "@/commons/libs/utils/apiUtils";

import { ConfirmActionForm } from "@/commons/domain/forms/confirm-action-form";

import contents from "@/manager/contents/deprecate-api-modal";

export default {
  name: "SwitchApiDeprecationModal2",
  components: { LayerModalFormConfirm, Markdown, DvpField, MTextInput },
  props: {
    deprecated: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      contents,
      form: ConfirmActionForm.create(),
    };
  },
  computed: {
    currentApi() {
      return this.$store.getters["currentApi"];
    },
    currentApiName(): string | undefined {
      return this.currentApi?.name;
    },
    isSaving() {
      return this.$store.getters["isSaving"];
    },
    title() {
      return this.deprecated
        ? contents.deprecateTitle(this.currentApiLabel)
        : contents.undeprecateTitle(this.currentApiLabel);
    },
    currentApiLabel(): string | undefined {
      return this.currentApi
        ? getApiLabelWithVersion(this.currentApi)
        : undefined;
    },
    isInvalidConfirmationApiName() {
      return this.form.firstError("text") != null;
    },
  },
  async mounted() {
    await this.form.init({ confirmationText: this.currentApiName });
  },
  methods: {
    async submit() {
      await this.form.validate();

      if (!this.isSaving && this.form.errors.length === 0) {
        await this.$store.dispatch("switchDeprecation", {
          apiId: this.currentApi?.id,
          deprecated: this.deprecated,
        });
      }
    },
  },
};
</script>

<style>
.switch-api-deprecation-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--base-spacing);
  height: 100%;
}
</style>
