import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContractCardWrapper = _resolveComponent("ContractCardWrapper")!
  const _component_ContractLoadingSkeleton = _resolveComponent("ContractLoadingSkeleton")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, { class: "contract-list" }, {
    default: _withCtx(() => [
      (!$props.isLoading)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($props.contracts, (contract) => {
            return (_openBlock(), _createBlock(_component_ContractCardWrapper, {
              key: contract.id,
              application: $props.application,
              contract: contract,
              onContractChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('contractChanged')))
            }, null, 8, ["application", "contract"]))
          }), 128))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(new Array(8), (item, index) => {
            return (_openBlock(), _createBlock(_component_ContractLoadingSkeleton, {
              key: `contract-loading-skeleton-${index}`
            }))
          }), 128))
    ]),
    _: 1
  }))
}