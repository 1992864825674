<template>
  <Layout2 class="application">
    <LayoutSection
      v-if="application"
      class="application__header"
      background="primary"
      noVerticalPadding
      fullWidthContent
    >
      <LayoutContentHeader
        :title="application.name"
        :previousPageRoute="previousPageRoute"
      >
        <div class="application__infos">
          <span
            v-if="application && application.createdAt"
            class="application__info"
          >
            <span class="application__info-label">
              {{ contents.createdAt }}:
            </span>
            <span>
              {{ date(application.createdAt, "DD/MM/YYYY - HH:mm") }}
            </span>
          </span>
          <span class="application__info">
            <span class="application__info-label">
              {{ contents.updateAt }}:
            </span>
            <span>
              {{ date(application.updatedAt, "DD/MM/YYYY - HH:mm") }}
            </span>
          </span>
          <span v-if="application.product" class="application__info">
            <span class="application__info-label">
              {{ contents.relatedProduct }}:
            </span>
            <router-link :to="'/docs/product/' + application.product.id">{{
              application.product.name
            }}</router-link>
          </span>
        </div>
        <template #actions>
          <MButton
            v-if="userIsInternal"
            data-cy="application-reporting-button"
            class="application__header__reporting-link-button"
            :label="contents.reporting"
            size="s"
            icon="DisplayExternalLink24"
            iconPosition="right"
            theme="bordered-neutral"
            :href="datadogLink()"
            target="_blank"
          />
          <MButton
            class="application__header__contact-owners-button"
            theme="bordered"
            size="s"
            :label="contents.contactOwners"
            :disabled="applicationHasNoContract"
            icon="ContactMail24"
            iconPosition="right"
            @click="contactOwners"
          />
        </template>
      </LayoutContentHeader>
    </LayoutSection>

    <LayoutSection background="primary" noVerticalPadding>
      <div class="application__menu">
        <div class="application__btn-actions gap-1200">
          <div
            v-for="btn in btnActions"
            :key="btn.route"
            :class="[
              'action',
              btn.route === activeRoute && 'btn-action-active',
            ]"
            :data-cy="`application-menu-${btn.label.toLowerCase()}`"
          >
            <router-link
              class="action-title"
              :to="{ name: btn.route, params: { id } }"
            >
              <h6>
                {{ btn.label }}
                {{
                  btn.route === "application" ? `(${totalContractsCount})` : ""
                }}
              </h6>
            </router-link>
          </div>
        </div>
      </div>
    </LayoutSection>

    <LayoutSection class="application__content" background="secondary">
      <router-view v-if="application && application.id === applicationId" />
    </LayoutSection>
  </Layout2>
</template>

<script lang="ts">
import MButton from "@mozaic-ds/vue-3/src/components/button/MButton.vue";

import Layout2 from "@/commons/components/Layout2.vue";
import LayoutContentHeader from "@/commons/components/LayoutContentHeader.vue";
import LayoutSection from "@/commons/components/LayoutSection.vue";
import SendEmailModal from "@/documentation/views/ApiDetail/SendEmailModal.vue";

import { date } from "@/commons/libs/utils/dateUtils";
import { getContractRepository } from "@/commons/repositories/libs/get-contract-repository";
import {
  DASHBOARD_ROUTE_NAME,
  getPreviousPageRoute,
} from "@/commons/utils/route-utils";

import { ContractMapper } from "@/commons/mappers/contract-mapper";

import { CONTRACT_STATUSES_FOR_APPLICATION_CONTRACTS_LISTING } from "@/commons/store/types";

import contents from "@/dashboard/contents/application";
import appContents from "@/dashboard/contents/application.json";
import summaryContents from "@/dashboard/contents/summary.json";
import sendEmailModalContents from "@/documentation/contents/send-email-modal";

export default {
  name: "Application",
  components: {
    LayoutContentHeader,
    Layout2,
    LayoutSection,
    MButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      applicationTypes: appContents.createApp.ui.applicationTypes,
      sumUi: summaryContents.ui,
      contents,
      readOnlyBtnActions: [
        {
          label: "Subscriptions",
          route: "application",
        },
      ],
      readWriteBtnActions: [
        {
          label: "Settings",
          route: "applicationSettings",
        },
      ],
      externalOnlyBtnActions: [
        {
          label: "Reporting",
          route: "applicationReporting",
        },
      ],
      previousPageRoute: getPreviousPageRoute(DASHBOARD_ROUTE_NAME),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoadingProperty"]("currentApplication");
    },
    application() {
      return this.$store.getters["currentApplication"];
    },
    applicationId() {
      return this.$route.params.id;
    },
    totalContractsCount(): number {
      return this.application?.metrics?.contracts?.total || 0;
    },
    btnActions() {
      let btnActions = this.readOnlyBtnActions;

      if (!this.userIsInternal) {
        btnActions = btnActions.concat(this.externalOnlyBtnActions);
      }

      if (
        this.application &&
        this.application.currentUserAction === "READ_WRITE"
      ) {
        btnActions = btnActions.concat(this.readWriteBtnActions);
      }

      return btnActions;
    },
    activeRoute() {
      return this.$route.name;
    },
    applicationHasNoContract() {
      return this.totalContractsCount === 0;
    },
    userIsInternal() {
      return this.$store.getters["userIsInternal"];
    },
  },
  async created() {
    await this.$store.dispatch("loadCurrentApplication", {
      appId: this.id,
    });
  },
  methods: {
    date,
    async getAllContractsOfCurrentApplication() {
      // For contacting owners we have to do a new fetch for contracts of other pages
      const paginatedContractsOfCurrentApplication =
        await getContractRepository().findByApplication(
          ContractMapper.toGetContractDTO({
            applicationId: this.id,
            size: 100,
            status: CONTRACT_STATUSES_FOR_APPLICATION_CONTRACTS_LISTING,
          }),
        );

      return Object.values(paginatedContractsOfCurrentApplication.data);
    },
    async getAllApisRelatedToCurrentApplication() {
      const allContracts = await this.getAllContractsOfCurrentApplication();
      return allContracts.map((contract) => contract.api);
    },
    async contactOwners() {
      this.$store.commit("openLayerModal", {
        title: sendEmailModalContents.modalTitle,
        props: {
          apis: await this.getAllApisRelatedToCurrentApplication(),
        },
        component: SendEmailModal,
      });
    },
    datadogLink(): string {
      return this.application.reportingUrl;
    },
  },
};
</script>

<style lang="scss">
@import "~@/commons/assets/styles/vars";

.application__header {
  border-bottom: var(--border-default);
}

.application__infos {
  @include set-text-s;
  display: flex;

  flex: 1;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  row-gap: 0.75rem;
}

.application__info {
  margin-right: 1rem;
}

.application__info-label {
  color: var(--color-grey);
}

.application__menu {
  background-color: var(--color-white);
}

.application__btn-actions {
  display: flex;
  width: var(--layout-content-width);
  margin: 0;

  .action {
    position: relative;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    border-top: 3px solid transparent;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;

    .action-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      h6 {
        margin: 0.5rem 0;
        font-weight: initial;
      }
    }
  }
  .btn-action-active {
    border-bottom: 3px solid var(--color-primary);
  }
}

.application__content {
  flex: 1;
}
</style>
