<script setup lang="ts">
import MIcon from "@mozaic-ds/vue-3/src/components/icon/MIcon.vue";
import MLink from "@mozaic-ds/vue-3/src/components/link/MLink.vue";
import { computed } from "vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  href: {
    type: String,
    required: true,
  },
  openInNewTab: {
    type: Boolean,
    default: false,
  },
  withIcon: {
    type: Boolean,
    default: true,
  },
});

const linkTarget = computed(() => (props.openInNewTab ? "_blank" : "_self"));
</script>

<template>
  <MLink class="help-link" :href="href" :target="linkTarget">
    <span class="help-link__label">{{ label }}</span>
    <MIcon
      v-if="withIcon"
      name="DisplayExternalLink16"
      class="help-link__icon"
    />
  </MLink>
</template>

<style lang="scss">
.help-link {
  display: inline-flex;
  align-items: center;
}

.help-link__icon {
  margin-left: 0.25rem;
}
</style>
