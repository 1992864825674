<template>
  <Layout2>
    <div class="request-manager">
      <div class="request-manager__left-panel">
        <h4>{{ contents.productListTitle }}</h4>
        <!-- FILTERS -->
        <div class="request-manager__filters">
          <!-- @TODO: implement search input -->

          <!-- FILTERS BY TYPE: EXTEND_TOKEN - NEW_TOKEN - NEW_CONTRACT - UPDATE_ACCESS_POLICY -->
          <AutoCompleteSelect
            v-model="selectedTypeItems"
            labelProperty="label"
            keyProperty="value"
            placeholder="Filter by request type"
            multiple
            :options="requestTypeItems"
          />

          <!-- FILTERS BY STATUS: APPROVAL_REQUIRED - APPROVED - DENIED - CANCELLED -->
          <AutoCompleteSelect
            v-model="selectedStatuses"
            labelProperty="label"
            keyProperty="value"
            multiple
            placeholder="Filter by request status"
            :options="statuses"
          />

          <!-- Filter request administrator => see all requests -->
          <AutoCompleteSelect
            v-model="selectedVisibility"
            labelProperty="name"
            keyProperty="value"
            placeholder="Filter by visibility"
            :options="visibilites"
            required
          />
        </div>
        <!-- REQUEST LIST -->
        <RequestManagerList />
        <div
          v-if="currentRequestsMaxCount > 0"
          class="gap-400 request-manager__pagination"
        >
          <MPagination
            :length="currentRequestsMaxCount"
            :value="urlQuery.page"
            @on-update-page="changePage($event)"
          />
        </div>
      </div>
      <div class="request-manager__right-panel">
        <Conversation :currentUser="currentUser" :urlQuery="urlQuery" />
      </div>
    </div>
  </Layout2>
</template>

<script lang="ts">
import MPagination from "@mozaic-ds/vue-3/src/components/pagination/MPagination.vue";

import AutoCompleteSelect from "@/commons/components/AutoCompleteSelect.vue";
import Layout2 from "@/commons/components/Layout2.vue";
import Conversation from "@/request-manager/views/Conversation.vue";

import RequestManagerList from "./RequestManagerList.vue";

import { changeUrlQuery } from "@/commons/utils/route-utils";

import {
  ERequestFilteringVisibility,
  ERequestStatus,
  ERequestType,
} from "@/commons/store/types";

import requestManagerContents from "@/request-manager/contents/request-manager-contents.js";

export default {
  name: "RequestManagerTemplate",
  components: {
    Conversation,
    RequestManagerList,
    Layout2,
    AutoCompleteSelect,
    MPagination,
  },
  props: {
    urlQuery: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contents: requestManagerContents.ui,
    };
  },
  computed: {
    currentRequestsMaxCount() {
      return this.$store.getters["currentRequestsMaxCount"];
    },
    currentUser() {
      return this.$store.getters["user"];
    },
    userIsAdmin() {
      return this.$store.getters["userIsAdmin"];
    },
    statuses() {
      const statusItems = [
        {
          label:
            requestManagerContents.statusLabels[
              ERequestStatus.APPROVAL_REQUIRED
            ],
          value: ERequestStatus.APPROVAL_REQUIRED,
        },
        {
          label: requestManagerContents.statusLabels[ERequestStatus.APPROVED],
          value: ERequestStatus.APPROVED,
        },
        {
          label: requestManagerContents.statusLabels[ERequestStatus.DENIED],
          value: ERequestStatus.DENIED,
        },
        {
          label: requestManagerContents.statusLabels[ERequestStatus.CANCELLED],
          value: ERequestStatus.CANCELLED,
        },
      ];

      return statusItems.sort((a, b) => a.label.localeCompare(b.label));
    },
    requestTypes(): ERequestType[] {
      return this.$store.state["requestManager"].requestTypes;
    },
    requestTypeItems(): { label: string; value: ERequestType }[] {
      const items = this.requestTypes.map((type) => ({
        label: requestManagerContents.requestTypeLabels[type] || type,
        value: type,
      }));

      return items.sort((a, b) => a.label.localeCompare(b.label));
    },
    visibilites() {
      let visibilities = [];
      if (this.userIsAdmin) {
        visibilities.push({
          name: "All",
          value: ERequestFilteringVisibility.ALL_VALIDABLE_BY_ADMIN,
        });
      }
      visibilities.push({
        name: "Received",
        value: ERequestFilteringVisibility.RECEIVED,
      });
      visibilities.push({
        name: "Submitted",
        value: ERequestFilteringVisibility.SUBMITTED,
      });
      return visibilities;
    },
    selectedTypeItems: {
      get() {
        return this.requestTypeItems.filter((type) =>
          this.urlQuery.types.includes(type.value),
        );
      },
      set(newSelectedTypeItems: any[]) {
        const types = newSelectedTypeItems.map((type) => type.value).join(",");
        this.changeUrlQuery({ types, page: "1" });
      },
    },
    selectedStatuses: {
      get() {
        const statuses = this.statuses;
        return statuses.filter((status) =>
          this.urlQuery.statuses.includes(status.value),
        );
      },
      set(statusesList: any[]) {
        const statuses = statusesList.map((status) => status.value).join(",");
        this.changeUrlQuery({ statuses, page: "1" });
      },
    },
    selectedVisibility: {
      get() {
        return this.visibilites.find(
          (visibilityOption) =>
            visibilityOption.value === this.urlQuery.visibility,
        );
      },
      set(visibilityOption: any) {
        if (visibilityOption) {
          this.changeUrlQuery({
            visibility: visibilityOption.value,
            page: "1",
          });
        }
      },
    },
  },
  methods: {
    changeUrlQuery(partial) {
      changeUrlQuery({
        requestId: "",
        ...partial,
      });
    },
    changePage(newPage) {
      this.changeUrlQuery({ page: newPage });
    },
  },
};
</script>

<style lang="scss" scoped>
$left-panel-width: 43rem;

.request-manager {
  background-color: var(--color-background-interface);
}

.request-manager__left-panel {
  position: fixed;
  top: 5rem;
  bottom: 0;
  left: 0;
  width: $left-panel-width;
  padding: 0 1.8rem;
  overflow-y: scroll;
  background-color: var(--color-white);
  border-top: var(--border-default);
}

.request-manager__left-panel h2 {
  margin: 1.5rem 0 1rem;
}

.request-manager__left-panel::-webkit-scrollbar {
  appearance: none;

  &-thumb {
    background-color: var(--color-grey);
    border-radius: 0;
  }
}

.request-manager__left-panel::-webkit-scrollbar:vertical {
  width: 0.571rem;
  background: var(--color-background-interface);
}

.request-manager__right-panel {
  position: fixed;
  inset: 5rem 0 0 $left-panel-width;
  background-color: var(--color-background-interface);
  border-top: var(--border-default);
}

.request-manager__filters > div {
  margin-bottom: 1rem;
}

.request-manager__pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
</style>
