import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "contract-card-documentation__link" }
const _hoisted_2 = { class: "link" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MIcon = _resolveComponent("MIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(_component_RouterLink, {
    class: "contract-card-documentation",
    to: {
      name: 'apiDocumentationDetail',
      params: { id: $props.contract.api.id },
    },
    target: "_blank"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_MIcon, { name: "ArrowArrowRight16" }),
        _createElementVNode("span", _hoisted_2, _toDisplayString($data.contents.documentation), 1)
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}