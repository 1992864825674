<template>
  <div
    class="contract-secret"
    :title="contents.copyClipboardTitle"
    @mouseenter="isVisibleSecret = true"
    @mouseleave="isVisibleSecret = false"
    @click="copyToClipboard"
  >
    <IconCopy class="contract-secret__copy-icon" />
    <span class="contract-secret__secret-display">{{ secretDisplay }}</span>
    <span class="contract-secret__eye-icon">
      <IconEyeOpen v-if="isVisibleSecret" />
      <IconEyeClose v-else />
    </span>
  </div>
</template>

<script lang="ts">
import IconCopy from "@/commons/components/Icons/IconCopy.vue";
import IconEyeClose from "@/commons/components/Icons/IconEyeClose.vue";
import IconEyeOpen from "@/commons/components/Icons/IconEyeOpen.vue";

import { writeText } from "@/commons/libs/write-text";

import contents from "@/dashboard/contents/contract-item";

export default {
  components: { IconCopy, IconEyeClose, IconEyeOpen },
  props: {
    secret: {
      type: String,
      required: true,
    },
  },
  data() {
    return { contents, isVisibleSecret: false };
  },
  computed: {
    secretDisplay() {
      return this.isVisibleSecret ? this.secret : "".padEnd(30, "-");
    },
  },
  methods: {
    copyToClipboard() {
      writeText(this.secret).then(() => {
        this.$store.commit("postWarningNotification", {
          title: this.contents.copyClipboardSuccess,
          message: this.contents.copyClipboardWarning,
        });
      });
    },
  },
};
</script>

<style lang="scss">
.contract-secret {
  @include set-text-s;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.375rem;
  cursor: pointer;
}

.contract-secret__secret-display {
  flex: 1;
  height: 1rem;
  word-break: break-all;
}

.contract-secret__copy-icon {
  margin-top: 0.125rem;
}

.contract-secret__eye-icon {
  display: inline-flex;
  margin-top: 0.125rem;
}
</style>
